import {Col, Container, Image, Row} from "react-bootstrap";
import teamPhoto from '../assets/fanplay.svg';
import React from "react";
const Disputes = () => {

    return (
        <Container className="about-us">
            <Row className="about-us-body">
                <Col xs={12} md={6}>
                    <Image src={teamPhoto} fluid/>
                </Col>
                <Col xs={12} md={6}>
                    <h2>How we handle disputes</h2>
                        <p>
                            If you want to file a dispute for any of your purchased services, please send an email to
                            <b> support@fan-play.com</b> and your dispute will be handled manually, and an equitable resolution
                            reached. We primarily rely upon our terms of service to influence our decisions.
                        </p>

                        <p>
                            A fully automated dispute system will be implemented shortly, and we are currently working on
                            it to ensure the process is as simple and quick as possible while maintaining our guarantee of
                            equitable results.
                        </p>
                        <p>
                            We appreciate your choice to use <b>Fanplay</b>, and consider it our absolute duty to ensure your
                            satisfaction. If you have any questions whatsoever, please email  <b>support@fan-play.com</b> and we
                            will quickly get back to you.
                        </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Disputes;