// Header.js
import NavBarIcons from "./NavBarIcons";

const Header = () => {
    return (
        <div>
            <NavBarIcons />
        </div>
    );
};

export default Header;