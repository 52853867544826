import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Row, Col, Image, Form, Button, Pagination } from 'react-bootstrap';
import GameJobService from '../services/GameJobService';
import Header from '../components/Header';
import ServiceCard from "../components/SmallServiceCard";
import LPHeader from "../components/LPHeader";
import AuthService from "../services/AuthService";

import digital from '../assets/vg.jpg'
import rpg from '../assets/clint-bustrillos-X-A-LJVAhzk-unsplash.png'
import tableTop from '../assets/denis-volkov-Q91-N8IsEoE-unsplash.jpg'
import card from '../assets/klim-musalimov-F7xRdXXvx9c-unsplash.jpg'

import minecraft from '../assets/icons8-minecraft-logo.svg'
import leagueOfLegends from '../assets/LoL_icon.svg.png'
import chess from '../assets/chess.svg'
import rocketLeague from '../assets/rocket-league-next-gen.jpg'
import valorant from '../assets/icons8-valorant.svg'
import fortnite from '../assets/FortniteLogo.svg'
import counterstrike from '../assets/counter-strike-global-offensive-soldier-graphics-background-wallpaper.jpg'
import dota from '../assets/icons8-dota-2.svg'


import USImg from '../assets/flags/us.svg'
import UKImg from '../assets/flags/gb.svg'
import FRImg from '../assets/flags/fr.svg'
import ESImg from '../assets/flags/es.svg'
import DEImg from '../assets/flags/de.svg'
import RUImg from '../assets/flags/ru.svg'
import CAImg from '../assets/flags/ca.svg'
import ITImg from '../assets/flags/it.svg'

const ServiceListingPage = () => {
    const [services, setServices] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [prevSearchTerm, setPrevSearchTerm] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;

    const gameCategoriesImages = [digital, rpg, tableTop, card];
    const gameCategoriesNames = ["Digital", "RPG", "Table Top", "Card"];
    const [isHovered, setIsHovered] = useState({index: null, type: null});

    const paginatedServices = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return services ? services.slice(startIndex, endIndex) : [];
    }, [services, currentPage]);

    // Total pages calculation
    const totalPages = useMemo(() => {
        return services ? Math.ceil(services.length / itemsPerPage) : 0;
    }, [services]);

    const loadAllServices = useCallback(async () => {
        try {
            const servicesData = await GameJobService.getAllServices();
            if (!Array.isArray(servicesData)) {
                console.log("No services found.");
                setServices([]);
            } else {
                setServices(servicesData);
                console.log('Listing page initial service data grab')
                console.log(servicesData)
            }
        } catch (error) {
            console.log("Error loading services:", error);
            setServices([]);
        }
    }, []);

    useEffect(() => {
        loadAllServices();
    }, [loadAllServices]);

    const filterByLocation = async (location) => {
        try {
            const servicesData = await GameJobService.getServicesBySellerLocation(location);
            if (!Array.isArray(servicesData)) {
                console.log("No services found for this location.");
                setServices([]);
            } else {
                setServices(servicesData);
            }
        } catch (error) {
            console.log("Error loading services by location:", error);
            setServices([]);
        }
    };

    const filterByGameCategory = async (gameCategory) => {
        try {
            const servicesData = await GameJobService.getServicesByGameCategory(gameCategory);
            if (!Array.isArray(servicesData)) {
                console.log("No services found for this game category.");
                setServices([]);
            } else {
                setServices(servicesData);
            }
        } catch (error) {
            console.log("Error loading services by game category:", error);
            setServices([]);
        }
    };

    const filterByGame = async (game) => {
        try {
            const servicesData = await GameJobService.getServicesByGame(game);
            if (!Array.isArray(servicesData)) {
                console.log("No services found for this game.");
                setServices([]);
            } else {
                setServices(servicesData);
            }
        } catch (error) {
            console.log("Error loading services by game:", error);
            setServices([]);
        }
    };

    // Handlers for image click
    const handleLocationImageClick = (location) => {
        filterByLocation(location);
    };

    const handleCategoryImageClick = (category) => {
        filterByGameCategory(category);
    };

    const handleGameImageClick = (game) => {
        filterByGame(game);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        if (searchTerm !== prevSearchTerm) {
            try {
                const servicesData = await GameJobService.searchServices(searchTerm);
                if (!Array.isArray(servicesData)) {
                    console.log("No services found for this search term.");
                    setServices([]);
                } else {
                    setServices(servicesData);
                }
            } catch (error) {
                console.log("Error loading services by search term:", error);
                setServices([]);
            }
            setPrevSearchTerm(searchTerm);
        }
    };

    const handleMouseEnter = (index, type) => {
        setIsHovered({index, type});
    };

    const handleMouseLeave = () => {
        setIsHovered({index: null, type: null});
    };

    const isLoggedIn = AuthService.isLoggedIn();

    return (
        <div>
            { isLoggedIn ? <Header /> : <LPHeader /> }
            <Container>

                <h2 className="section-header" id="categories-header-service-listing">Categories</h2>

                <Row className="game-category-img subcategories">
                    {gameCategoriesImages.map((image, index) => (
                        <Col xs={6} md={3} style={{position: 'relative'}} key={index}>
                            <img
                                className={`subcat-img ${isHovered.type === 'game-category' && isHovered.index !== index ? 'not-hovered' : ''}`}
                                src={image}
                                alt="Subcategory"
                                onMouseEnter={() => handleMouseEnter(index, 'game-category')}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleCategoryImageClick(gameCategoriesNames[index])}
                            />
                            <div className="image-text">{gameCategoriesNames[index]}</div>
                        </Col>
                    ))}
                </Row>

               <h2 className="section-header">Locations</h2>
            <Row className="mb-3">
                <Col xs={3} md={1.5}><Image className="location-img" style={{ width: '60px', height: '60px' }} src={USImg} roundedCircle onClick={() => handleLocationImageClick('United States')}/></Col>
                <Col xs={3} md={1.5}><Image className="location-img" style={{ width: '60px', height: '60px' }} src={UKImg} roundedCircle onClick={() => handleLocationImageClick('United Kingdom')}/></Col>
                <Col xs={3} md={1.5}><Image className="location-img" style={{ width: '60px', height: '60px' }} src={FRImg} roundedCircle onClick={() => handleLocationImageClick('France')}/></Col>
                <Col xs={3} md={1.5}><Image className="location-img" style={{ width: '60px', height: '60px' }} src={ESImg} roundedCircle onClick={() => handleLocationImageClick('Spain')}/></Col>
            </Row>
             <Row className="mb-3">
                <Col xs={3} md={1.5}><Image className="location-img" style={{ width: '60px', height: '60px' }} src={DEImg} roundedCircle onClick={() => handleLocationImageClick('Germany')}/></Col>
                <Col xs={3} md={1.5}><Image className="location-img" style={{ width: '60px', height: '60px' }} src={RUImg} roundedCircle onClick={() => handleLocationImageClick('Russia')}/></Col>
                <Col xs={3} md={1.5}><Image className="location-img" style={{ width: '60px', height: '60px' }} src={CAImg} roundedCircle onClick={() => handleLocationImageClick('Canada')}/></Col>
                <Col xs={3} md={1.5}><Image className="location-img" style={{ width: '60px', height: '60px' }} src={ITImg} roundedCircle onClick={() => handleLocationImageClick('Italy')}/></Col>
            </Row>
                <h2 className="section-header" id="popular-games-header">Popular Games</h2>
                    <Row>
                        <Col xs={3} md={1.5}>
                            <Image className="game-img" src={chess} style={{position: 'relative', width: '60px', height: '60px'}} roundedCircle onClick={() => handleGameImageClick('Chess')}/>
                        </Col>
                        <Col xs={3} md={1.5}>
                            <Image className="game-img" style={{ width: '80px', height: '80px' }} src={leagueOfLegends} style={{position: 'relative', width: '60px', height: '60px'}} roundedCircle onClick={() => handleGameImageClick('LoL')}/>
                        </Col>
                        <Col xs={3} md={1.5}>
                            <Image className="game-img" style={{ width: '80px', height: '80px' }} src={minecraft} style={{position: 'relative', width: '60px', height: '60px'}}roundedCircle onClick={() => handleGameImageClick('Minecraft')}/>
                        </Col>
                        <Col xs={3} md={1.5}>
                            <Image className="game-img" style={{ width: '80px', height: '80px' }} src={counterstrike} style={{position: 'relative', width: '60px', height: '60px'}} roundedCircle onClick={() => handleGameImageClick('Counterstrike')}/>
                        </Col>
                        <Col xs={3} md={1.5} style={{ marginTop: '20px' }}>
                            <Image className="game-img" style={{ width: '80px', height: '80px' }} src={dota} style={{position: 'relative', width: '60px', height: '60px'}} roundedCircle onClick={() => handleGameImageClick('Dota')}/>
                        </Col>
                        <Col xs={3} md={1.5} style={{ marginTop: '20px' }}>
                            <Image className="game-img" style={{ width: '80px', height: '80px' }} src={fortnite} style={{position: 'relative', width: '60px', height: '60px'}} roundedCircle onClick={() => handleGameImageClick('Fortnite')}/>
                        </Col>
                        <Col xs={3} md={1.5} style={{ marginTop: '20px' }}>
                            <Image className="game-img" style={{ width: '80px', height: '80px' }} src={rocketLeague} style={{position: 'relative', width: '60px', height: '60px'}} roundedCircle onClick={() => handleGameImageClick('Rocket League')}/>
                        </Col>
                        <Col xs={3} md={1.5} style={{ marginTop: '20px' }}>
                            <Image className="game-img" style={{ width: '80px', height: '80px' }} src={valorant} style={{position: 'relative', width: '60px', height: '60px'}} roundedCircle onClick={() => handleGameImageClick('Valorant')}/>
                        </Col>
                    </Row>

                <Row className="justify-content-md-between py-3 reduce-padding-bottom">
                    <Col md="auto">
                        <Form onSubmit={handleSearchSubmit}>
                            <Form.Control
                                className="form-box"
                                id="search"
                                type="text"
                                placeholder="🔍 Search for anything (e.g Singapore/Chess/Poker)"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onKeyUp={event => {
                                    if (event.key === "Enter") {
                                        handleSearchSubmit();
                                    }
                                }}
                            />
                            <Button type="submit" style={{display: "none"}}>Search</Button>
                        </Form>
                    </Col>
                    </Row>
                <Row>
                    {paginatedServices.length > 0 ? paginatedServices.map(service => (
                        <Col xs={12} sm={6} md={4} lg={3} key={service.id}>
                            <ServiceCard service={service} />
                        </Col>
                    )) : <p>No services to display.</p>}
                </Row>
                <Pagination className="justify-content-center">
                    {[...Array(totalPages).keys()].map(number => (
                        <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => setCurrentPage(number + 1)}>
                            {number + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </Container>
        </div>
    );
};

export default ServiceListingPage;

