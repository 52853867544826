import React from 'react';
import '../css/AboutUsPage.css';
import LPHeader from "../components/LPHeader";
import Header from "../components/Header";
import AuthService from "../services/AuthService";
import Disputes from "../components/Disputes";

const AboutUsPage = () => {
    const isLoggedIn = AuthService.isLoggedIn();
    return (
        <div>
            { isLoggedIn ? <Header /> : <LPHeader /> }
            <Disputes />
        </div>
    );
};

export default AboutUsPage;