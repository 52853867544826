import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import UserService from "../services/UserService";
import AuthService from "../services/AuthService";

const InfluencerForm = () => {
    const [name, setName] = useState("");
    const [brandName, setBrandName] = useState("");
    const [instagram, setInstagram] = useState("");
    const [twitter, setTwitter] = useState("");
    const [youtube, setYoutube] = useState("");
    const [facebook, setFacebook] = useState("");
    const [comments, setComments] = useState("");
    const navigate = useNavigate();

    const userId = AuthService.getUserId()

    if (!userId) {

    }

    const handleApply = async (event) => {
        event.preventDefault();

        const influencerRequest = {
            name,
            brandName,
            youtube,
            instagram,
            twitter,
            facebook,
            comments
        };

        try {
            const response = await UserService.applyForInfluencer(userId, influencerRequest);
            console.log('Application successful!');
            setTimeout(() => {
                navigate('/home');
            }, 1000);
        } catch (error) {
            console.error('Error during application:', error);
        }
    };


    const handleInvalidInput = (event, inputName) => {
        event.target.setCustomValidity("");
        if (!event.target.validity.valid) {
            event.target.setCustomValidity(`Please enter a valid ${inputName}`);
        }
    };return (
        <div className="form-container">
            <form onSubmit={handleApply} className="form-box">
                <h3 style={{ textAlign: 'center' }}>Sell as an Influencer</h3>

                <div className="mb-3">
                    <label style={{ fontWeight: '500' }}>Brand/Name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Brand name"
                        pattern=".{1,}"
                        value={brandName}
                        onChange={e => setBrandName(e.target.value)}
                        onInvalid={(e) => handleInvalidInput(e, 'brand')}
                        required
                    />
                </div>

                <div className="mb-3">
                    <label style={{ fontWeight: '500' }}>Instagram Handle</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Instagram handle"
                        pattern="@[A-Za-z0-9_\.]{1,}"
                        value={instagram}
                        onChange={e => setInstagram(e.target.value)}
                        onInvalid={(e) => handleInvalidInput(e, 'Instagram handle')}
                    />
                </div>

                <div className="mb-3">
                    <label style={{ fontWeight: '500' }}>Twitter Handle</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Twitter handle"
                        pattern="@[A-Za-z0-9_\.]{1,}"
                        value={twitter}
                        onChange={e => setTwitter(e.target.value)}
                        onInvalid={(e) => handleInvalidInput(e, 'Twitter handle')}
                    />
                </div>

                <div className="mb-3">
                    <label style={{ fontWeight: '500' }}>Facebook Handle</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Facebook handle"
                        pattern="@[A-Za-z0-9_\.]{1,}"
                        value={facebook}
                        onChange={e => setFacebook(e.target.value)}
                        onInvalid={(e) => handleInvalidInput(e, 'Facebook handle')}
                    />
                </div>

                <div className="mb-3">
                    <label style={{ fontWeight: '500' }}>YouTube Handle</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your YouTube handle"
                        pattern="@[A-Za-z0-9_\.]{1,}"
                        value={youtube}
                        onChange={e => setYoutube(e.target.value)}
                        onInvalid={(e) => handleInvalidInput(e, 'YouTube handle')}
                    />
                </div>

                <div className="mb-3">
                    <label style={{ fontWeight: '500' }}>Additional Comments</label>
                    <textarea
                        className="form-control"
                        placeholder="Any additional information?"
                        value={comments}
                        onChange={e => setComments(e.target.value)}
                    />
                </div>

                <div className="d-grid">
                    <button type="submit" className="btn btn-primary">
                        Apply as Influencer
                    </button>
                </div>
            </form>
        </div>
    );
}

export default InfluencerForm;