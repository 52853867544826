// UserProfileServiceDetails.js
import React, { useState, useRef, useEffect } from 'react';
import {Card, Col, Row, Container, Form, Button, Modal } from "react-bootstrap";
import GameJobService from "../services/GameJobService";
import { getDefaultImageUrl } from "../utils/Helper";
import { useParams, useNavigate } from 'react-router-dom';
import { LinkContainer } from "react-router-bootstrap";
import {StarFill, Pencil, Trash, Check} from "react-bootstrap-icons";
import AuthService from "../services/AuthService";
import gameJobService from "../services/GameJobService";
import VisualButton from "./VisualButton";

const MobileProfileServiceDetails = ({ user, handlePurchase, isLoggedIn }) => {
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [detailedService, setDetailedService] = useState(null);
    const { serviceId } = useParams();
    const loggedInUserId = AuthService.getUserId();
    const [gameName, setGameName] = useState('');
    const [description, setDescription] = useState('');
    const [gameCategory, setGameCategory] = useState('');
    const [price, setPrice] = useState('0.00');
    const [serviceImage, setServiceImage] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [viewMode, setViewMode] = useState('view');
    // Add a state variable for the service title
    const [serviceName, setServiceName] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editServiceMode, setEditServiceMode] = useState(false);
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');

    const confirmDelete = () => setShowDeleteModal(true);

    const isValidImageSize = async (image) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = URL.createObjectURL(image);
            img.onload = function() {
                resolve(this.width <= 1000 && this.height <= 1000);
            };
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log(file); // Check the file in console
        setServiceImage(file);
    }

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const toggleServiceEditMode = () => {
        setEditServiceMode(!editServiceMode);
    };

    const handleDelete = async () => {
        try {
            await GameJobService.deleteAGameJob(loggedInUserId, serviceId);
            // Handle successful deletion, e.g., redirect or update UI
        } catch (error) {
            console.error('Error deleting game job:', error);
            // Handle error
        }
        setShowDeleteModal(false);
    };

    const saveGameJob = async (e) => {
        e.preventDefault();

        const priceNumber = parseFloat(price);
        if (isNaN(priceNumber) || priceNumber < 2.5) {
            setErrorMessage('Price must be greater than or equal to $2.5');
            return;
        }

        const updatedGameJobDTO = {
            name: serviceName,
            description: description,
            gameCategory: gameCategory,
            game: gameName,
            price: priceNumber,
            sellerId: loggedInUserId,
        };

        try {
            await gameJobService.updateGameJob(serviceId, updatedGameJobDTO);

            if (serviceImage) {
                const validImageSize = await isValidImageSize(serviceImage);
                if (!validImageSize) {
                    setErrorMessage("Image dimensions should not exceed 1000x1000 pixels.");
                    return;
                }

                // Separate call for uploading the image
                await gameJobService.updateGameJobImage(serviceId, serviceImage);
            }

            setEditServiceMode(false);
            await fetchServiceDetails();
        } catch (error) {
            console.error('Error saving game job:', error);
            setErrorMessage('Failed to save changes. Please try again.');
        }
    };


    // Mapping from string values to enum values
    const gameCategoryMapping = {
        "Digital": "DIGITAL",
        "Table Top": "TABLE_TOP",
        "RPG": "RPG",
        "Card": "CARD"
    };

    // Define fetchServiceDetails function outside of useEffect
    const fetchServiceDetails = async () => {
        try {
            const data = await GameJobService.getService(serviceId);
            setDetailedService(data);

            // Update form fields with fetched data
            setServiceName(data.name);
            setGameName(data.game);
            setDescription(data.description);
            setGameCategory(data.gameCategory);
            setPrice(data.price.toFixed(2));
            const mappedGameCategory = gameCategoryMapping[data.gameCategory] || data.gameCategory;
            setGameCategory(mappedGameCategory);

        } catch (error) {
            console.error("Error fetching service details: ", error);
        }
    };

    useEffect(() => {
        // Fetch details when the component mounts or serviceId changes
        fetchServiceDetails();
    }, [serviceId]);

    useEffect(() => {
        // Fetch updated details when switching back to view mode
        if (viewMode === 'view') {
            fetchServiceDetails();
        }
    }, [viewMode, serviceId]);

    const handlePurchaseClick = () => {
        if (!isLoggedIn) {
            navigate('/sign-up');
        } else {
            handlePurchase();
        }
    }

    return (
        <>
            <Container style={{ marginBottom: '60px' }}>
                <Row className="justify-content-md-center">
                        <Col xs={12} md={8} lg={12}>
                        <Card className="shadow" style={{ minHeight: '60vh', width: '100%' }}>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} md={8}>
                                        {editServiceMode ? (
                                            <Form onSubmit={saveGameJob}>
                                                <Form.Group controlId="serviceName">
                                                    <Form.Label>Service Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={serviceName}
                                                        onChange={(e) => setServiceName(e.target.value)}
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="gameName">
                                                    <Form.Label>Game Name</Form.Label>
                                                    <Form.Control type="text" value={gameName}
                                                                  onChange={(e) => setGameName(e.target.value)}/>
                                                </Form.Group>

                                                <Form.Group controlId="description">
                                                    <Form.Label>Description</Form.Label>
                                                    <Form.Control type="text" value={description}
                                                                  onChange={(e) => setDescription(e.target.value)}/>
                                                </Form.Group>

                                                {/* Dropdown for gameCategory */}
                                                <Form.Group controlId="gameCategory">
                                                    <Form.Label>Game Category</Form.Label>
                                                    <Form.Control as="select" value={gameCategory}
                                                                  onChange={(e) => setGameCategory(e.target.value)}>
                                                        <option value="DIGITAL">Digital</option>
                                                        <option value="TABLE_TOP">Table Top</option>
                                                        <option value="RPG">RPG</option>
                                                        <option value="CARD">Card</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group controlId="price">
                                                    <Form.Label>Price (Minimum $2.5)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                        pattern="^\d*(\.\d{0,2})?$"
                                                        title="Price must be a number with up to two decimal places"
                                                    />
                                                </Form.Group>

                                                <Form.Group controlId="serviceImage">
                                                    <Form.Label>Service Image</Form.Label>
                                                    <Form.Control type="file" onChange={handleImageChange}/>
                                                </Form.Group>

                                                {errorMessage &&
                                                    <div className="alert alert-danger">{errorMessage}</div>}

                                                <div className="icon-buttons">
                                                    <div className="icon-button-container">
                                                        <VisualButton
                                                            icon={Check}
                                                            text="Submit"
                                                            variant="primary"
                                                            type="submit"
                                                            className="submit-button"
                                                        />
                                                    </div>
                                                </div>
                                            </Form>
                                        ) : (
                                            detailedService && (
                                                <div id="user-profile-service-details-div"
                                                     style={{textAlign: 'center'}}>
                                                    <h3 style={{marginTop: '10px'}}>{detailedService.name}</h3>
                                                    <img className="user-service-img"
                                                         style={{marginTop: '0', maxWidth: '100%'}}
                                                         src={detailedService.imageUrl} alt={detailedService.name}/>

                                                    {/* Game and Category in the same row */}
                                                    <Row style={{marginTop: '20px'}}>
                                                        {/* Game */}
                                                        <Col xs={6} md={6}>
                                                            <h5 style={{
                                                                fontFamily: 'Roboto',
                                                                fontWeight: '500',
                                                                fontSize: '18px'
                                                            }}>Game ♟️</h5>
                                                            <p style={{
                                                                fontFamily: 'Roboto',
                                                                fontSize: '18px',
                                                                marginTop: '20px'
                                                            }}>{detailedService.game}</p>
                                                        </Col>
                                                        {/* Category */}
                                                        <Col xs={6} md={6}>
                                                            <h5 style={{
                                                                fontFamily: 'Roboto',
                                                                fontWeight: '500',
                                                                fontSize: '18px'
                                                            }}>Category 🗃️</h5>
                                                            <p style={{
                                                                fontFamily: 'Roboto',
                                                                fontSize: '18px',
                                                                marginTop: '20px'
                                                            }}>{detailedService.gameCategory}</p>
                                                        </Col>
                                                    </Row>

                                                    {/* Price and Rating in the same row */}
                                                    <Row style={{marginTop: '20px'}}>
                                                        <Col xs={6} md={6}>
                                                            <h5 style={{fontSize: '18px'}}>Price 💰</h5>
                                                            <p className="price" style={{
                                                                fontFamily: 'Roboto',
                                                                fontSize: '18px',
                                                                marginTop: '20px'
                                                            }}>${detailedService.price}</p>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <h5 style={{fontSize: '20px', marginBottom: '18px'}}>Rating
                                                                ⭐</h5>
                                                            <p style={{fontFamily: 'Roboto', fontSize: '18px'}}>
                                                                <StarFill className="star-icon"/>
                                                                <span style={{fontSize: '16px'}}>
                                                                        <b>{detailedService.meanRating} / 5</b> <br/> (based on {detailedService.ratingCount} reviews)
                                                                    </span>
                                                            </p>
                                                        </Col>
                                                    </Row>

                                                    <h5 style={{
                                                        fontFamily: 'Roboto',
                                                        fontWeight: '500',
                                                        fontSize: '18px'
                                                    }}>Description ✍️</h5>
                                                    <p style={{
                                                        textAlign: 'left',
                                                        marginRight: '10%',
                                                        marginLeft: '10%',
                                                        fontFamily: 'Roboto',
                                                        fontSize: '18px'
                                                    }}>{detailedService.description}</p>
                                                </div>
                                            )
                                        )
                                        }
                                    </Col>
                                    <Col xs={12} md={4} className="d-flex flex-column align-items-center">
                                        {isLoggedIn && Number(user.id) !== Number(loggedInUserId) && (
                                            <Button variant="primary" className="mt-3" onClick={handlePurchaseClick}>
                                                Purchase Service
                                            </Button>
                                        )}
                                        {isLoggedIn && Number(user.id) === Number(loggedInUserId) && (
                                            <div className="text-center mt-3">
                                                <div>
                                                    <VisualButton
                                                        icon={Pencil}
                                                        text="Edit"
                                                        variant="primary"
                                                        onClick={toggleServiceEditMode}
                                                        className="button-no-row"
                                                    />
                                                </div>
                                                <div>
                                                    <VisualButton
                                                        icon={Trash}
                                                        text="Delete"
                                                        variant="danger"
                                                        onClick={confirmDelete}
                                                        className="button-no-row"/>
                                                </div>
                                            </div>
                                        )}
                                        <h4 style={{marginTop: "10px"}}>Sold By</h4>
                                        <img
                                            src={getDefaultImageUrl(user.imageUrl)}
                                            alt={`${user.firstName} ${user.lastName}`}
                                            className="profile-img-mob-det"
                                            style={{marginBottom: "0"}} // Adjusted marginBottom
                                        />
                                        {editMode ?
                                            <>
                                                <Form.Control type="text" value={firstName}
                                                              onChange={(e) => setFirstName(e.target.value)}/>
                                                <Form.Control type="text" value={lastName}
                                                              onChange={(e) => setLastName(e.target.value)}/> {/* Corrected value assignment for lastName */}
                                            </>
                                            :
                                            <LinkContainer to={`/profile/${user.id}`} style={{marginTop: '0'}}>
                                                <h3>
                                                    <a className="service-details-name-link text-center"
                                                       href={`/profile/${user.id}`} style={{marginTop: "0"}}>
                                                        {user.firstName} {user.lastName}
                                                    </a>
                                                </h3>
                                            </LinkContainer>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        </Col>
                </Row>
            </Container>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Service</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this service? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
    </>
    );
};

export default MobileProfileServiceDetails;
