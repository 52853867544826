import React, { useState, useEffect } from 'react';
import UserService from "../services/UserService";
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {Cart, StarFill} from 'react-bootstrap-icons';
import {LinkContainer} from "react-router-bootstrap";
import AuthService from "../services/AuthService";
import {getDefaultImageUrl, getDefaultServiceImageUrl} from "../utils/Helper";
import PurchaseButton from "./PurchaseButton";

const UserServicesSmallSellerCard = ({ service, onEditClick, editable = false, handlePurchase, onProfile }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const loadUserProfile = async () => {
            const userProfile = await UserService.getProfileInformation(service.sellerId);
            setUser(userProfile);
        };
        loadUserProfile();
    }, [service.id]);

    const handleClick = () => {
        navigate(`/services/${service.id}`);
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    const isLoggedIn = AuthService.isLoggedIn();

    const cardHeight = isLoggedIn ? '360px' : '320px';

    const cardStyle = {
        // Add additional styling properties as needed
        width: '172px', // Set the initial width
        margin: '0',
        height: cardHeight
    };

    return (
        <Card className="mb-4 service-card shadow" style={{ marginTop: '6px' }} onClick={handleClick} style={cardStyle}>
            <div className="d-flex align-items-center p-1">
                <Card.Img variant="top" className="profile-image" src={user.imageUrl || getDefaultImageUrl()}
                          alt="Profile Image"/>
                <LinkContainer to={`/profile/${user.id}`}>
                    <a className="service-details-name-link job-title single-line-text" href={`/profile/${user.id}`}>
                        {user.firstName} {user.lastName}
                    </a>
                </LinkContainer>
            </div>
            <Card.Body>
                <div className="d-flex align-items-center" style={{ marginTop: '0 !important' }}>
                    {!onProfile && (
                        <Card.Img variant="top" src={getDefaultServiceImageUrl(service.imageUrl)} alt="Service Image"
                                  style={{ marginBottom: '0px !important' }}/>
                    )}
                </div>
                <Card.Text style={{ marginLeft: '6px', paddingTop: '0 !important', paddingBottom: '0 !important', marginTop: '0 !important', marginBottom: '0', fontSize: '18px', fontFamily: 'Roboto'}}
                           className="job-title single-line-text" id="service-card-name">
                    {service.name}
                </Card.Text>
                <Card.Text style={{ marginLeft: '6px', marginRight: '8px', marginBottom: '8px', fontSize: '14px', fontFamily: 'Roboto' }} className="single-line-text">
                    {service.description}
                </Card.Text>
                <Card.Text style={{marginLeft: '6px', marginBottom: '8px', fontSize: '12px' }} className="d-flex align-items-center">
                    <StarFill className="star-icon" />
                    <span style={{ fontSize: '12px' }}>
                        <b>{service.meanRating} / 5</b> (based on {service.ratingCount} reviews)
                    </span>
                </Card.Text>
                <Card.Text className="price" style={{ marginLeft: '6px', marginBottom: '0', paddingBottom: '0', fontSize: '14px' }}>
                    Price: <span style={{ marginLeft: '6px' }}>${service.price}</span>
                </Card.Text>
                {isLoggedIn && (
                    <div>
                        <PurchaseButton
                            icon={Cart}
                            text="Purchase"
                            variant="primary"
                            onClick={handlePurchase}
                            // Add any other props you want to pass
                        />
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};
export default UserServicesSmallSellerCard;