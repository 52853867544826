import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Image } from 'react-bootstrap';
import logo from '../assets/fanplay.svg';
import {useNavigate} from "react-router-dom";
import '../css/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCog, faUser, faEnvelope, faMoneyBillWave, faTag, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import AuthService from "../services/AuthService";

const NavBar = () => {
    const userId = localStorage.getItem('userId');
    const userRole = localStorage.getItem('role');

    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            // Call the API to invalidate the token
            await AuthService.logout();

            // Clear local storage and navigate to root
            localStorage.clear();
            navigate("/home");
            window.location.reload();
        } catch (error) {
            console.error('Logout failed:', error);
            // Handle error appropriately
        }
    };

    return (
        <Navbar expand="lg" style={{ backgroundColor: "#FFFFFF" }}>
            <LinkContainer to="/home" className="nav-item">
                <Navbar.Brand className="navbar-brand-margin">
                    <Image src={logo} width="30" height="30" alt="Fanplay logo" />
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <Nav className="ml-auto custom-nav">
                <LinkContainer to="/" className="nav-item">
                        <Nav.Link className="nav-link-text"><FontAwesomeIcon icon={faHome} className="white-icon" />
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/service-listing" className="nav-item">
                        <Nav.Link className="nav-link-text"><FontAwesomeIcon icon={faCog} className="white-icon" />
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={`/profile/${userId}`} className="nav-item">
                        <Nav.Link className="nav-link-text"><FontAwesomeIcon icon={faUser} className="white-icon" />
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={`/messages/${userId}`} className="nav-item">
                        <Nav.Link className="nav-link-text"><FontAwesomeIcon icon={faEnvelope} className="white-icon" />
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/transactions" className="nav-item">
                        <Nav.Link className="nav-link-text"><FontAwesomeIcon icon={faMoneyBillWave} className="white-icon" />
                        </Nav.Link>
                    </LinkContainer>
                    {(userRole === 'SELLER' || userRole === 'ADMIN') && (
                        <LinkContainer to="/selling" className="nav-item">
                            <Nav.Link className="nav-link-text"><FontAwesomeIcon icon={faTag} className="white-icon" />
                            </Nav.Link>
                        </LinkContainer>
                    )}
                    <Nav.Link onClick={handleLogout} className="nav-link-text logout-button white-icon">
                        <FontAwesomeIcon icon={faDoorOpen} className="white-icon" />
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavBar;