import React from 'react';
import { FaTimes } from 'react-icons/fa';
import Header from "../components/Header";
import LPHeader from "../components/LPHeader";
import {Link} from 'react-router-dom';
import AuthService from "../services/AuthService";

const PaymentCancelledPage = () => {

    const isLoggedIn = AuthService.isLoggedIn();

    return (
        <div>
            {isLoggedIn ? <Header /> : <LPHeader />}
            <div className="order-complete-page" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto', minHeight: '100vh' }}>
                <div className="order-confirmation" style={{ textAlign: 'center', marginTop: '40px' }}>
                    <h1 style={{ fontSize: '28px' }}>Your payment was cancelled!</h1>
                    <FaTimes style={{ fontSize: '50px', color: 'red' }} />
                </div>
                <div className="order-review" style={{ textAlign: 'center', margin: '20px 0 0 0' }}>
                    <h2 className="margin-bot" style={{ fontSize: '24px' }}>Please try again, <br /> or contact our support team</h2>
                </div>
                <Link to="/contact" style={{ fontSize: '24px', textDecoration: 'none', color: '#007bff', marginTop: '0' }}>Contact Support</Link>
            </div>
        </div>
    );
};

export default PaymentCancelledPage;