import React from 'react';
import '../css/AboutUsPage.css';
import LPHeader from "../components/LPHeader";
import Header from "../components/Header";
import AuthService from "../services/AuthService";
import Contact from "../components/Contact";

const ContactPage = () => {
    const isLoggedIn = AuthService.isLoggedIn();
    return (
        <div style={{ marginBottom: '100px' }}>
            { isLoggedIn ? <Header /> : <LPHeader /> }
            <Contact />
        </div>
    );
};

export default ContactPage;