import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Pagination, Row, Col, Container, Button, Card } from 'react-bootstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../css/HomePage.css";
import AuthService from "../services/AuthService";
import GameJobService from '../services/GameJobService';
import UserService from "../services/UserService";
import useWindowSize from "../utils/useWindowSize";
import Header from '../components/Header';
import LPHeader from "../components/LPHeader";
import ServiceCard from '../components/SmallServiceCard';
import UserCard from "../components/UserCard";
import MobileCarousel from '../components/MobileCarousel';

// Images
import homeslide1 from '../assets/slide5homepage.png';
import homeslide2 from '../assets/slide2homepage.png';
import homeslide3 from "../assets/slide3.png";
import homeslide4 from "../assets/slide4.png";
import homeslide5 from "../assets/21.png";
import homeslideMobile2 from "../assets/lpmobileslide4new.png";

import Slide2LargeScreen from '../components/Slide2LargeScreen';
import Slide2SmallScreen from '../components/Slide2SmallScreen';

const HomePage = () => {

    const [recentServices, setRecentServices] = useState(null);
    const [featuredServices, setFeaturedServices] = useState(null);
    const [featuredSellers, setFeaturedSellers] = useState(null);
    const isLoggedIn = AuthService.isLoggedIn();

    const { width } = useWindowSize();
    const breakpoint = 436;

    // Pagination state for each section
    const [currentPageFeaturedSellers, setCurrentPageFeaturedSellers] = useState(1);
    const [currentPageFeaturedServices, setCurrentPageFeaturedServices] = useState(1);
    const [currentPageRecentServices, setCurrentPageRecentServices] = useState(1);
    const itemsPerPage = 8;

    // Function to paginate data
    const paginate = (data, currentPage) => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
    };

    const [currentSlides, setCurrentSlides] = useState({
        slide1: homeslide1,
        slide2: homeslide2,
        slide3: homeslide3,
        slide4: homeslide4,
        slide5: homeslide5,
    });

    useEffect(() => {
        const handleResize = () => {
                if (window.innerWidth <= 882) {
                    setCurrentSlides({
                        slide1: homeslide1,
                        slide2: homeslideMobile2,
                        slide3: homeslide3,
                        slide4: homeslide4,
                        slide5: homeslide5,
                    });
            } else {
                setCurrentSlides({
                    slide1: homeslide1,
                    slide2: homeslide2,
                    slide3: homeslide3,
                    slide4: homeslide4,
                    slide5: homeslide5,
                });
            }
        };

        window.addEventListener('resize', handleResize);

        // Call the function to set the initial state
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const loadRecentServices = async () => {
            try {
                const servicesData = await GameJobService.getServicesByRecency();
                if (!Array.isArray(servicesData)) {
                    console.log("No recent services found.");
                }
                setRecentServices(servicesData);
            } catch (error) {
                console.log("Error loading recent services:", error);
            }
        };

        const loadFeaturedServices = async () => {
            try {
                const servicesData = await GameJobService.getServicesByFeatured();
                if (!Array.isArray(servicesData)) {
                    console.log("No featured services found.");
                }
                setFeaturedServices(servicesData);
            } catch (error) {
                console.log("Error loading featured services:", error);
            }
        };

        const loadFeaturedSellers = async () => {
            try {
                const servicesData = await UserService.getSellersByFeatured();
                if (!Array.isArray(servicesData)) {
                    console.log("No featured services found.");
                }
                setFeaturedSellers(servicesData);
            } catch (error) {
                console.log("Error loading featured services:", error);
            }
        };

        loadRecentServices();
        loadFeaturedServices();
        loadFeaturedSellers();
    }, []);

    // Updated Pagination Render Logic
    const renderPagination = (data, currentPage, setCurrentPageFunction) => {
        if (!data) return null; // Check if data is not null

        const totalPages = Math.ceil(data.length / itemsPerPage);
        return (
            <Pagination className="justify-content-center">
                {[...Array(totalPages).keys()].map(number => (
                    <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => setCurrentPageFunction(number + 1)}>
                        {number + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        );
    };

    return (
        <div id="homeContainer">
            { isLoggedIn ? <Header /> : <LPHeader /> }
            <div className="carousel-container">
                <div className="carousel-container">
                    {width < breakpoint ? <MobileCarousel /> : (
                        <Carousel
                            autoPlay
                            infiniteLoop
                            showThumbs={false}
                            className="home-carousel"
                        >
                                <div className="carousel-div">
                                    <img className="carousel-image" src={currentSlides.slide1} alt="Slideshow 1"/>
                                </div>

                            {width <= 882 ?
                                <Slide2SmallScreen src={currentSlides.slide2} /> :
                                <Slide2LargeScreen src={currentSlides.slide2} />
                            }

                            <div className="carousel-div">
                                    <img className="carousel-image" src={currentSlides.slide3} alt="Slideshow 3"/>
                                </div>
                                {currentSlides.slide4 && (
                                    <div className="carousel-div">
                                        <img className="carousel-image" src={currentSlides.slide4} alt="Slideshow 4"/>
                                    </div>
                                )}
                                {currentSlides.slide5 && (
                                    <div className="carousel-div">
                                        <img className="carousel-image" src={currentSlides.slide5} alt="Slideshow 5"/>
                                    </div>
                                )}
                            </Carousel>
                    )}
                </div>
            </div>
            <Container>
                <h2 className="home-headers" style={{marginTop: '10px'}}>Featured Services</h2>
                <Row className="home-row">
                    {featuredServices ? paginate(featuredServices, currentPageFeaturedServices).map(service => (
                        <Col xs={6} sm={6} md={4} lg={3} key={service.id} style={{ marginBottom: '10px' }}>
                            <ServiceCard service={service} />
                        </Col>
                    )) : <p>Loading...</p>}
                </Row>
                {renderPagination(featuredServices, currentPageFeaturedServices, setCurrentPageFeaturedServices)}

                <h2 className="home-headers">Featured Sellers</h2>
                <Row className="home-row">
                    {featuredSellers ? paginate(featuredSellers, currentPageFeaturedSellers).map(user => (
                        <Col xs={6} sm={6} md={4} lg={3} key={user.id} style={{ marginBottom: '10px' }}>
                            <UserCard user={user} />
                        </Col>
                    )) : <p>Loading...</p>}
                </Row>
                {renderPagination(featuredSellers, currentPageFeaturedSellers, setCurrentPageFeaturedSellers)}

                <h2 className="home-headers">New Services</h2>
                <Row className="home-row">
                    {recentServices ? paginate(recentServices, currentPageRecentServices).map(service => (
                        <Col xs={6} sm={6} md={4} lg={3} key={service.id} style={{ marginBottom: '10px' }}>
                            <ServiceCard service={service} />
                        </Col>
                    )) : <p>Loading...</p>}
                </Row>
                {renderPagination(recentServices, currentPageRecentServices, setCurrentPageRecentServices)}
            </Container>
        </div>
);
};

export default HomePage;