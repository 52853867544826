import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/game-jobs';

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error)
    }
);

class GameJobService {

    async getService(serviceId) {
        const response = await axiosInstance.get(`/get/${serviceId}`);
        return response.data;
    }

    async getAllServices() {
        const response = await axiosInstance.get('');
        return response.data;
    }

    async getServicesBySellerLocation(location) {
        const response = await axiosInstance.get(`/location/${location}`);
        return response.data;
    }

    async getServicesByGameCategory(gameCategory) {
        const response = await axiosInstance.get(`/category/${gameCategory}`);
        return response.data;
    }

    async getServicesByGame(game) {
        const response = await axiosInstance.get(`/game/${game}`);
        return response.data;
    }

    async getServicesByRecency() {
        const response = await axiosInstance.get(`/new`);
        return response.data;
    }

    async getServicesByFeatured() {
        const response = await axiosInstance.get(`/featured`);
        return response.data;
    }

    async addGameJob(gameJobDto) {
        const response = await axiosInstance.post('', gameJobDto, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    }

    async updateGameJob(gameJobId, gameJobDto) {
        const response = await axiosInstance.post(`/${gameJobId}`, gameJobDto);
        return response.data;
    }

    async updateGameJobImage(gameJobId, file) {
        const formData = new FormData();
        formData.append('serviceImage', file); // Make sure this matches the backend's expected parameter name

        try {
            const response = await axiosInstance.post(`/${gameJobId}/image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // This is usually set automatically
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    }

    async purchaseGameJob(userId, gameJobId) {
        const response = await axiosInstance.post(`/${gameJobId}/purchase`, { id: userId });
        return response.data;
    }

    async addGameJobReview(reviewDTO, gameJobId, userId) {
        const response = await axiosInstance.post(`/${gameJobId}/${userId}/review`, reviewDTO);
        return response.data;
    }

    async getGameJobReviews(gameJobId) {
        const response = await axiosInstance.get(`/${gameJobId}/reviews`);
        return response.data;
    }

    async getTransactions(userId) {
        const response = await axiosInstance.get(`/${userId}/transactions`);
        return response.data;
    }

    async getServicesBySeller(userId) {
        const response = await axiosInstance.get(`/${userId}/seller`);
        return response.data;
    }

    async getTransactionsBySeller(userId) {
        const response = await axiosInstance.get(`/${userId}/seller/transaction`);
        return response.data;
    }

    async getOfferForASellerWithGameJob(gameJobId, sellerId, buyerId) {
        const response = await axiosInstance.get(`/${gameJobId}/seller/${sellerId}/buyer/${buyerId}/offer`);
        return response.data;
    }

    async modifyOffer(gameJobId, offerId, offerRequest) {
        const response = await axiosInstance.post(`/${gameJobId}/offer/${offerId}`, offerRequest);
        return response.data;
    }

    async makeOffer(gameJobId, offerRequest) {
        const response = await axiosInstance.post(`/${gameJobId}/offer`, offerRequest);
        return response.data;
    }

    async getOffersForSeller(sellerId, withRejected) {
        const response = await axiosInstance.get(`/seller/${sellerId}/offers`, withRejected);
        return response.data;
    }

    async getReceivedOffers(userId) {
        const response = await axiosInstance.get(`/offers/${userId}`);
        return response.data;
    }

    async searchServices(searchTerm) {
        let endpoint = '/search';
        if (searchTerm) {
            endpoint += `?searchTerm=${searchTerm}`;
        }
        const response = await axiosInstance.get(endpoint);
        return response.data;
    }

    async getAllPurchasedGameJobsForBuyer(buyerId) {
        const response = await axiosInstance.get(`/${buyerId}/purchased-game-jobs`);
        return response.data;
    }
    async getPurchasedGameJobDetails(buyerId, purchasedServiceId) {
        const response = await axiosInstance.get(`/buyer/${buyerId}/${purchasedServiceId}/purchased-game-job`);
        return response.data;
    }

    async editRequirements(editRequirementsOrUpdatesPurchasedGameJobDTO, purchasedGameJobId) {
        const fullUrl = `/${purchasedGameJobId}/request-requirements-change`;
        const response = await axiosInstance.post(fullUrl, editRequirementsOrUpdatesPurchasedGameJobDTO);
        return response.data;
    }

    async addUpdates(editRequirementsOrUpdatesPurchasedGameJobDTO, purchasedGameJobId) {
        const response = await axiosInstance.post(`/${purchasedGameJobId}/update-updates`, editRequirementsOrUpdatesPurchasedGameJobDTO);
        return response.data;
    }
    async getInProgressSoldGameJobs(sellerId) {
        const response = await axiosInstance.get(`/${sellerId}/sold-game-jobs-in-progress`);
        return response.data;
    }
    async updateStatus(newStatus, purchasedServiceId, sellerId) {
        const response = await axiosInstance.post(`/${sellerId}/${purchasedServiceId}/update-status`, newStatus);
        return response.data;
    }
    async cancelOrder(userId, purchasedServiceId) {
        const response = await axiosInstance.post(`/${userId}/${purchasedServiceId}/cancel`);
        return response.data;
    }

    async getRequirementRequests(purchasedGameJobId) {
        const fullUrl = `/${purchasedGameJobId}/requirement-requests`;
        const response = await axiosInstance.get(fullUrl);
        return response.data;
    }

    async acceptRequirementsRequest(sellerId, requestId) {
        const fullUrl = `/change-requests/${sellerId}/${requestId}/approve`;
        const response = await axiosInstance.post(fullUrl);
        return response.data;
    }

    async rejectRequirementsRequest(sellerId, requestId) {
        const fullUrl = `/change-requests/${sellerId}/${requestId}/reject`;
        const response = await axiosInstance.post(fullUrl);
        return response.data;
    }

    // Inside GameJobService class
    async updateDeliveryDate(purchasedGameJobId, deliveryDateDTO) {
        const response = await axiosInstance.post(`/${purchasedGameJobId}/update-delivery-date`, deliveryDateDTO);
        return response.data;
    }

    async uploadGameJobImage(serviceId, serviceImage) {
        const response = await axiosInstance.post(`/${serviceId}/image`, serviceImage);
        return response.data;
    }

    async getAllInProgressPurchasedGameJobsForBuyer(buyerId) {
        const response = await axiosInstance.get(`/${buyerId}/in-progress-purchased-game-jobs`);
        return response.data;
    }

    async deleteAGameJob(gameJobId, buyerId) {
        const response = await axiosInstance.post(`/${gameJobId}/${buyerId}/delete`);
        return response.data;
    }

    async getAllCompletedPurchasedGameJobsForBuyer(buyerId) {
        const response = await axiosInstance.get(`/${buyerId}/completed-purchased-game-jobs`);
        return response.data;
    }

    async getCompletedSoldGameJobs(sellerId) {
        const response = await axiosInstance.get(`/${sellerId}/sold-game-jobs-completed`);
        return response.data;
    }

    async isReviewed(purchasedGameJobId, buyerId) {
        const response = await axiosInstance.get(`/${purchasedGameJobId}/${buyerId}/isReviewed`);
        return response.data; // Assuming the endpoint returns a boolean
    }
}
export default new GameJobService();