// UserProfileServiceDetails.js
import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Container, Modal, Button, Dropdown, Alert, Form} from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import {getDefaultImageUrl, getDefaultServiceImageUrl} from "../utils/Helper";
import AuthService from "../services/AuthService";
import GameJobService from "../services/GameJobService";

const UserProfileServiceDetails = ({ detailedService, fetchDetailedService }) => {
    const isCompleted = false;
    const isDelivered = detailedService.progress === 'Delivered';
    const isReadyForReview = detailedService.progress === 'Ready for Review';
    const isCancelled = detailedService.progress === 'Cancelled';
    const loggedInUserId = AuthService.getUserId();
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showDeliveryDateModal, setShowDeliveryDateModal] = useState(false);
    const [deliveryDays, setDeliveryDays] = useState('');
    const [showRequirementsAlert, setShowRequirementsAlert] = useState(false);

    const navigate = useNavigate();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showCancellationConfirmationModal, setShowCancellationConfirmationModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);
    const [requirementRequests, setRequirementRequests] = useState([]);
    const [showRequirementsModal, setShowRequirementsModal] = useState(false);

    const handleShowRequirementsModal = () => {
        setShowRequirementsModal(true);
    };
    const handleCloseRequirementsModal = () => {
        setShowRequirementsModal(false);
        };
    // In CompleteOrderPage component
    useEffect(() => {
        // Immediately invoke the async function inside the useEffect
        (async () => {
            try {
                const hasReviewed = await GameJobService.isReviewed(detailedService.id, loggedInUserId);
                if (!hasReviewed && isDelivered && (Number(loggedInUserId) !== Number(detailedService.seller.id))) {
                    const timer = setTimeout(() => {
                        navigate(`/purchased-service/${detailedService.id}/complete-order`, {
                            state: {gameJobId: detailedService.gameJobResponseDTO.id}
                        });
                    }, 5000);

                    return () => clearTimeout(timer);
                }
            } catch (error) {
                console.error("Error checking if reviewed:", error);
                // Handle error, possibly setting an error message in state
            }
        })();
    }, [detailedService, loggedInUserId]);



    useEffect(() => {
        let intervalId;

        const fetchRequirementRequests = async () => {
            try {
                const response = await GameJobService.getRequirementRequests(detailedService.id);
                setRequirementRequests(response);
                setShowRequirementsAlert(response.length > 0);
            } catch (error) {
                console.error('Error fetching requirement requests:', error);
            }
        };

        if (Number(loggedInUserId) === Number(detailedService.seller.id)) {
            fetchRequirementRequests(); // Fetch immediately on mount
            intervalId = setInterval(fetchRequirementRequests, 30000); // Fetch every 30 seconds
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [detailedService, loggedInUserId]);

    // Modified handleShowModal function to accept a parameter for updates
    const handleShowModal = (content, isAllUpdates = false) => {
        if (isAllUpdates) {
            // If it's for all updates, we prepare a different content
            const updatesContent = (
                <ul>
                    {detailedService.updateList.map((update, index) => (
                        <li key={index}>
                            <strong>{update.time} {update.date}</strong> - {update.message}
                        </li>
                    ))}
                </ul>
            );
            setModalContent(updatesContent);
        } else {
            // If it's for single content, we show it as before
            setModalContent(content);
        }
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    // Function to handle adding new requirement
    const handleAddRequirement = () => {
        setModalContent(
            <form onSubmit={submitNewRequirement}>
            <textarea
                placeholder="Edit Requirements"
                className="form-control"
                required
            />
                <Button type="submit" className="mt-2">Submit</Button>
            </form>
        );
        setShowModal(true);
    };

    // Function to handle adding new event

    const handleAddEvent = () => {
        setModalContent(
            <form onSubmit={submitNewEvent}>
                <div className="mb-3">
                    <label htmlFor="updateMessage" className="form-label">Update Message</label>
                    <textarea
                        id="updateMessage"
                        placeholder="Enter new event message"
                        className="form-control"
                        required
                    />
                </div>
                <Button type="submit" className="mt-2">Add Update</Button>
            </form>
        );
        setShowModal(true);
    };

    // Function to handle status change
    const handleStatusChange = async (newStatus) => {
        // Map the display name to the enum constant
        const statusMap = {
            'Not Started': 'NOT_STARTED',
            'In Progress': 'IN_PROGRESS',
            'Ready for Review': 'READY_FOR_REVIEW',
            'Delivered': 'DELIVERED'
        };

        const enumStatus = statusMap[newStatus];

        if (enumStatus) {
            const hasReviewed = await GameJobService.isReviewed(detailedService.id, loggedInUserId);
            if(!hasReviewed) {
                await GameJobService.updateStatus(enumStatus, detailedService.id, loggedInUserId);
                fetchDetailedService();
                }
        } else {
            console.error('Invalid status:', newStatus);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // Function to get the emoji and color based on the current status
    const getStatusEmojiAndColor = (status) => {
        switch (status) {
            case 'Not Started':
                return { emoji: '⏰', color: '🔴' };
            case 'In Progress':
                return { emoji: '🚧', color: '🟠' };
            case 'Ready for Review':
                return { emoji: '🔍', color: '🟡' };
            case 'Delivered':
                return { emoji: '📦', color: '🟢' };
            case 'Cancelled':
                return { emoji: '❌', color: '🟣' };
            default:
                return { emoji: '', color: '' };
        }
    };

    const { emoji, color } = getStatusEmojiAndColor(detailedService.progress);

    // Function to handle submission of new requirement
    const submitNewRequirement = async (event) => {
        event.preventDefault();
        const newRequirements = event.target[0].value;

        const requirementsDTO= {
            "requirements": newRequirements
        }

        try {
            // Call to editRequirements happens here
            await GameJobService.editRequirements(requirementsDTO, detailedService.id);
            handleCloseModal();
            fetchDetailedService();
            setShowSuccessAlert(true); // Show the success alert
            // Optional: Use a timeout to hide the alert after a few seconds
            setTimeout(() => setShowSuccessAlert(false), 8000);
        } catch (error) {
            console.error('Error submitting new requirement:', error);
        }
    };


    const handleAcceptClick = () => {
        setConfirmAction('accept');
        setShowConfirmationModal(true);
    };

    const handleRejectClick = () => {
        setConfirmAction('reject');
        setShowConfirmationModal(true);
    };

    const confirmAcceptOrReject = async () => {
        const newStatus = confirmAction === 'accept' ? 'Delivered' : 'In Progress';
        await handleStatusChange(newStatus);
        setShowConfirmationModal(false);
    };

    // This function is triggered when the "Cancel Order" button is clicked.
    const handleCancelOrder = () => {
        // Set the action type to 'cancel' for conditional rendering in the modal
        setConfirmAction('cancel');
        // Show the confirmation modal
        setShowCancellationConfirmationModal(true);
    };

    // This function is triggered when the "Confirm" button in the confirmation modal is clicked.
    const confirmCancel = async () => {
        try {
            // Perform the cancellation logic, e.g., making an API call to cancel the order
            await GameJobService.cancelOrder(loggedInUserId, detailedService.id);
            // After the order is cancelled, fetch the updated details to reflect in the UI
            fetchDetailedService();
        } catch (error) {
            // Handle any errors that occur during cancellation
            console.error('Error cancelling the order:', error);
        }
        // Hide the confirmation modal after the action is confirmed
        setShowCancellationConfirmationModal(false);
    };

    // Function to handle submission of new event
    const submitNewEvent = async (event) => {
        event.preventDefault();
        const message = event.target.updateMessage.value;

        const updateDTO = {
            "message": message
        };

        const editRequirementsOrUpdatesPurchasedGameJobDTO = {
            "updateDTO": updateDTO
        };

        try {
            await GameJobService.addUpdates(editRequirementsOrUpdatesPurchasedGameJobDTO, detailedService.id);
            handleCloseModal();
            fetchDetailedService();
        } catch (error) {
            console.error('Error submitting new event:', error);
        }
    };

    const handleAcceptRequirementsRequestClick = async (requestId) => {
        try {
            await GameJobService.acceptRequirementsRequest(loggedInUserId, requestId);
            setRequirementRequests(requirementRequests.filter(request => request.id !== requestId));
            fetchDetailedService();
        } catch (error) {
            console.error('Error accepting requirements request:', error);
        }
    };

    const handleRejectRequirementsRequestClick = async (requestId) => {
        try {
            await GameJobService.rejectRequirementsRequest(loggedInUserId, requestId);
            setRequirementRequests(requirementRequests.filter(request => request.id !== requestId));
            fetchDetailedService();
            handleCloseRequirementsModal(); // Close the modal after successful rejection
            setShowRequirementsAlert(false); // Stop showing the alert
        } catch (error) {
            console.error('Error rejecting requirements request:', error);
        }
    };
    const handleDeliveryDateSubmit = async () => {
        // Create the DTO for delivery date
        const deliveryDateDTO = {
            deliveryDays: parseInt(deliveryDays)
        };

        // Call the service to update the delivery date
        try {
            await GameJobService.updateDeliveryDate(detailedService.id, deliveryDateDTO);
            setShowDeliveryDateModal(false);
            fetchDetailedService(); // Refresh the details to show updated date
        } catch (error) {
            console.error('Error updating delivery date:', error);
        }
    };

    console.log(detailedService);
    return (
        <Container style={{ marginBottom: '20px' }}>
            {showSuccessAlert && (
                <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
                    Requirements submitted successfully, currently awaiting review by the seller.
                </Alert>
            )}
            {showRequirementsAlert && (
                <Alert variant="success" style={{ cursor: 'pointer' }} onClose={() => setShowRequirementsAlert(false)} onClick={handleShowRequirementsModal} dismissible>
                    New Requirements Request: Click here to review it!
                </Alert>
            )}
            <Row className="justify-content-md-center">
                <Col xs={12} md={10} lg={12}>
                    <Card className="shadow" style={{ minHeight: '116vh', width: '100%' }}>
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={3} className="d-flex flex-column align-items-center">
                                    {/* Ternary operator to check if the logged-in user is the seller. If so, display buyer's image and name. */}
                                    <img
                                        src={Number(loggedInUserId) === Number(detailedService.seller.id)
                                            ? getDefaultImageUrl(detailedService.buyer.imageUrl)
                                            : getDefaultImageUrl(detailedService.seller.imageUrl)}
                                        alt={`${Number(loggedInUserId) === Number(detailedService.seller.id)
                                            ? `${detailedService.buyer.firstName} ${detailedService.buyer.lastName}`
                                            : `${detailedService.seller.firstName} ${detailedService.seller.lastName}`}`}
                                        className="service-details-profile-img profile-img"
                                        style={{ borderRadius: '0 !important' }}
                                    />
                                    <h6 style={{ marginTop: '10px', fontSize: '24px' }}>
                                        <Link
                                            to={`/profile/${Number(loggedInUserId) === Number(detailedService.seller.id) ? detailedService.buyer.id : detailedService.seller.id}`}
                                            style={{
                                                color: 'black',
                                                textDecoration: 'none',
                                                fontStyle: 'none'
                                            }}
                                        >
                                            {Number(loggedInUserId) === Number(detailedService.seller.id)
                                                ? `${detailedService.buyer.firstName} ${detailedService.buyer.lastName}`
                                                : `${detailedService.seller.firstName} ${detailedService.seller.lastName}`}
                                        </Link>
                                    </h6>

                                    {/* Dropdown to change status (visible only to seller) */}
                                    {Number(loggedInUserId) === Number(detailedService.seller.id) ? (
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success">
                                                {color} {detailedService.progress} {emoji}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleStatusChange('Not Started')}>
                                                    🔴 Not Started ⏰
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleStatusChange('In Progress')}>
                                                    🟠 In Progress 🚧
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleStatusChange('Ready for Review')}>
                                                    🟡 Ready for Review 🔍
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (
                                        <p>{color} <span id="status-font">{detailedService.progress}</span> {emoji}</p>
                                    )}
                                    <div
                                        style={{ marginTop: '18px' }}
                                        className={`delivery-date-info ${!detailedService.deliveryDate ? 'clickable' : ''}`}
                                        onClick={() => !detailedService.deliveryDate && setShowDeliveryDateModal(true)}
                                        title={!detailedService.deliveryDate ? "Click to edit" : ""}
                                    >
                                        <h5>📅 Delivery Date</h5>
                                        <p className="text-align-center delivery-date-text">
                                            {detailedService.deliveryDate ? formatDate(detailedService.deliveryDate) : "N/A"}
                                        </p>
                                    </div>
                                    {/* Check if the service is ready for review */}
                                    {isReadyForReview && (
                                        <>
                                            {/* Conditional rendering based on user role */}
                                            {Number(loggedInUserId) === Number(detailedService.buyer.id) ? (
                                                <div className="d-flex flex-column align-items-center">
                                                    <h5>Accept/Reject Delivery:</h5>
                                                    <p className="text-align-center">Your order is waiting to be reviewed!</p>
                                                    <div className="d-flex justify-content-center">
                                                        <Button onClick={handleAcceptClick} style={{ backgroundColor: 'green' }} className="mx-2">Accept</Button>
                                                        <Button onClick={handleRejectClick} style={{ backgroundColor: 'red' }} className="mx-2">Reject</Button>
                                                    </div>
                                                </div>
                                            ) : Number(loggedInUserId) === Number(detailedService.seller.id) ? (
                                                <div className="d-flex justify-content-center">
                                                    <p className="text-align-center">📦 Your delivery is waiting to be reviewed!</p>
                                                </div>
                                            ) : null}
                                        </>
                                    )}

                                    {isDelivered && (
                                        <div className="d-flex justify-content-center">
                                            <h5>This order is complete!</h5>
                                        </div>
                                    )}
                                </Col>
                                <Col xs={12} md={6}>
                                    {/* Requirements Section */}
                                    <div className="bio-box">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5>Requirements:</h5>
                                            {/* Button to add new requirement (visible only to buyer) */}
                                            {Number(loggedInUserId) === Number(detailedService.buyer.id) && !isCancelled && (
                                                <Button variant="outline-primary" onClick={handleAddRequirement}>
                                                    +
                                                </Button>
                                            )}
                                        </div>
                                        <p className="text-with-ellipsis" onClick={() => handleShowModal(detailedService.requirements)}>
                                            {detailedService.requirements}
                                        </p>
                                    </div>
                                    {/* Updates Section */}
                                    <div className="bio-box updates">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5>Updates:</h5>
                                            {/* Button to add new event (visible only to seller) */}
                                            {Number(loggedInUserId) === Number(detailedService.seller.id) && !isCancelled && (
                                                <Button variant="outline-primary" onClick={handleAddEvent}>
                                                    +
                                                </Button>
                                            )}
                                        </div>
                                        <ul>
                                            {detailedService.updateList.map((update, index) => (
                                                <li key={index} className="text-with-ellipsis" onClick={() => handleShowModal(update.message, true)}>
                                                    <strong>{update.status}</strong>:           {update.message}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <div className="user-service-img-container">
                                        <img className="user-service-img" src={getDefaultServiceImageUrl(detailedService.gameJobResponseDTO.imageUrl)} alt={detailedService.name} />
                                    </div>
                                    <h4 className="single-line-text" style={{ fontSize: '24px', textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>{detailedService.gameJobResponseDTO.name}</h4>

                                    <Col style={{ height: '10px', width: '40%', margin: '0 auto' }}> {/* Show 'Cancel Order' button only if logged-in user is the seller */}
                                        <Row>
                                        {Number(loggedInUserId) === Number(detailedService.seller.id) && !isDelivered && (
                                                <button className="btn btn-danger" onClick={handleCancelOrder} style={{ marginRight: '4px' }}>Cancel Order</button>
                                            )}
                                        </Row>
                                        <Row style={{ marginTop: '10px' }}>
                                        {/* Show 'Dispute Order' button if logged-in user is the buyer or the seller */}
                                            {(Number(loggedInUserId) === Number(detailedService.buyer.id) || Number(loggedInUserId) === Number(detailedService.seller.id)) && (
                                                <Link to="/orders/disputes" className="btn btn-warning">Dispute Order</Link>
                                            )}
                                        </Row>
                                        </Col>

                                    <h6 style={{ textAlign: 'center', marginTop: '90px' }}>File Sharing - Coming Soon!</h6>
                                    <p style={{ textAlign: 'center' }}>Use GitHub for code, or cloud storage services to share files, or email!</p>

                                    {/* Requirement Requests Notification */}
                                    {requirementRequests && requirementRequests.length > 0 && (
                                        <div>
                                            <div onClick={handleShowRequirementsModal} style={{ cursor: 'pointer' }}>
                                                New Requirements Request: Click here to review it!
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* Modal for showing full text */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    {modalContent}
                </Modal.Body>
            </Modal>
            <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to {confirmAction === 'accept' ? 'accept' : 'reject'} the delivery?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={confirmAcceptOrReject}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showCancellationConfirmationModal} onHide={() => setShowCancellationConfirmationModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Cancellation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to cancel the delivery?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCancellationConfirmationModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={confirmCancel}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal for showing requirement requests */}
            <Modal show={showRequirementsModal} onHide={handleCloseRequirementsModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Requirement Requests</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    <p>The buyer has submitted a new requirements request, please review it and accept/reject it.</p>
                    <p>Please only accept one of these requirements updates.</p>
                    {/* Display the list of requirement requests */}
                    {requirementRequests?.map((request, index) => (
                        <div key={index}>
                            <h6>Requirement Request {index}:</h6>
                            <p>{request.newRequirements}</p>
                            <Button
                                onClick={() => handleAcceptRequirementsRequestClick(request.id)}
                                style={{ backgroundColor: 'green' }}
                                className="mx-2">
                                Accept
                            </Button>
                            <Button
                                onClick={() => handleRejectRequirementsRequestClick(request.id)}
                                style={{ backgroundColor: 'red' }}
                                className="mx-2">
                                Reject
                            </Button>
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseRequirementsModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeliveryDateModal} onHide={() => setShowDeliveryDateModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Set Delivery Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="deliveryDays">
                        <Form.Label>Enter Number of Days for Delivery (from 1 to 365)</Form.Label>
                        <Form.Control
                            type="number"
                            min="1"
                            max="365"
                            placeholder="Enter days (1-365)"
                            value={deliveryDays}
                            onChange={(e) => setDeliveryDays(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeliveryDateModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleDeliveryDateSubmit}>
                        Set Date
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default UserProfileServiceDetails;

