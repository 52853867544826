import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Slide2SmallScreen = ({ src }) => {
    // Inline style for centering content
    const centerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Use the full height of the screen
        textAlign: 'center',
        position: 'absolute', // Ensure it's on top of the image
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Adjust for exact centering
        zIndex: 2, // Ensure it's above the image
    };

    // Inline style for the image
    const imageStyle = {
        width: '100%',
        height: '100vh', // Full height of the screen
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1, // Ensure it's behind the content
    };

    // Inline style for the text
    const textStyle = {
        marginTop: '20px', // Space between button and text
        color: '#333',
        fontSize: '20px',
        fontFamily: 'Roboto',
        fontWeight: '500',
    };

    return (
        <div style={{ position: 'relative', height: '100vh' }}>
            <img src={src} alt="Slideshow 2" style={imageStyle} />
            <div style={centerStyle}>
                <Link to="/become-a-seller">
                    <Button variant="primary" style={{ width: '240px', marginTop: '120px', marginBottom: '0' }} as="span" className="large-button">
                        Become a Seller
                    </Button>
                </Link>
                <p style={textStyle}>Selling Made Simple</p>
            </div>
        </div>
    );
};

export default Slide2SmallScreen;
