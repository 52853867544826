import React, {useState, useEffect, useRef} from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import userService from "../services/UserService";
import GameJobService from "../services/GameJobService";
import '../css/MessagingPage.css';
import {Link} from "react-router-dom";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import {getDefaultImageUrl} from "../utils/Helper";
import { useLocation } from 'react-router-dom';
import StripeService from "../services/StripeService";
import {loadStripe} from "@stripe/stripe-js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ConversationDetail = ({ conversation, userId }) => {

    const [message, setMessage] = useState("");
    const [offerPrice, setOfferPrice] = useState("");
    const [services, setServices] = useState([]);
    const [offer, setOffer] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [currentOffer, setCurrentOffer] = useState(null);
    const [sellerId, setSellerId] = useState(null);
    const [buyerId, setBuyerId] = useState(null);
    const gameJobId = selectedService?.id;
    const [showOfferUI, setShowOfferUI] = useState(false);
    const [otherUser, setOtherUser] = useState(null);
    const [receivedOffers, setReceivedOffers] = useState([]);
    const [counterOfferPrice, setCounterOfferPrice] = useState("");
    const [feedback, setFeedback] = useState("");
    const messagesEndRef = useRef(null);
    const [messages, setMessages] = useState(conversation.messages || []);
    const location = useLocation();
    const recipientName = location.state?.recipientName;
    const [loggedInUserProfile, setLoggedInUserProfile] = useState(null);

   const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const fetchMessages = async () => {
        if (conversation && userId) {
            try {
                // Assuming there's a function in your service to get messages between two users
                const fetchedMessages = await userService.getMessagesBetweenUsers(userId, conversation.id);
                setMessages(fetchedMessages);
            } catch (error) {
                console.error("Error fetching messages:", error);
            }
        }
    };

    useEffect(() => {
        fetchMessages();
    }, [conversation, userId]); // This useEffect will run when conversation or userId changes


    useEffect(() => {
        // Fetch profile information for the logged-in user
        const fetchLoggedInUserProfile = async () => {
            try {
                const profileData = await UserService.getProfileInformation(userId);
                setLoggedInUserProfile(profileData);
            } catch (error) {
                console.error(`Error fetching profile for logged-in user:`, error);
            }
        };

        if (userId) {
            fetchLoggedInUserProfile();
        }
    }, [userId]);


    useEffect(() => {
        if (!conversation) return;

        setOtherUser(conversation);

        const fetchServicesForUser = async (userId) => {
            try {
                const servicesData = await GameJobService.getServicesBySeller(userId);
                return servicesData;
            } catch (error) {
                console.error(`Error fetching services for user ${userId}:`, error);
                return [];
            }
        }

        const fetchAllServices = async () => {

                try {
                    const myServices = await GameJobService.getServicesBySeller(userId);
                    setServices(myServices);
                } catch (error) {
                    console.error(`Error fetching services for user ${userId}:`, error);
                    setServices([]);
                }

        };

    const fetchReceivedOffers = async () => {
            try {
                const offers = await GameJobService.getReceivedOffers(userId);
                setReceivedOffers(offers.filter(offer => offer.buyerId === conversation.id));
            } catch (error) {
                console.error("Error fetching received offers:", error);
            }
        }

        fetchAllServices();
        if (otherUser) {
            fetchReceivedOffers();
        }
    }, [conversation, otherUser]);


    const handleServiceSelection = (selected) => {
        const parsedService = JSON.parse(selected);
        setSelectedService(parsedService);
        setBuyerId(userId === parsedService.sellerId ? null : userId);
    };

    const handleSend = async (event) => {
        event.preventDefault();

        if (message.length === 0) {
            return
        }

        const newMessage = {
            "recipientId": conversation.id,
            "topic": conversation.topic,
            "msg": message,
            "senderId": userId,
        };

        userService.saveMessageForUser(userId, newMessage)
            .then(() => {
                // Update local messages state
                setMessages(prevMessages => [...prevMessages, newMessage]);
                setMessage(""); // clear the message field after sending
            })
            .catch(error => {
                console.error("Error sending message:", error);
            });
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [conversation]);

    const handleSendOffer = async () => {

        if (parseFloat(offerPrice) < 2.5) {
            setFeedback("Offer price should be $2.5 or higher!");
            return;
        }

        const sellerId = AuthService.getUserId();
        const buyerId = otherUser.id;

        const offerRequest = {
            sellerId: Number(sellerId),
            buyerId: buyerId,
            gamejobId: gameJobId,
            offerPrice: parseFloat(offerPrice),
            status: "COUNTERED"
        };

        try {
            if (currentOffer) {
                await GameJobService.modifyOffer(gameJobId, currentOffer.id, offerRequest);
            } else {
                await GameJobService.makeOffer(gameJobId, offerRequest);
            }
            setFeedback("Offer sent successfully!");  // Provide feedback
            setShowOfferUI(false);  // Close the offer form
            setSelectedService(null);  // Reset the selected service

            // Reset feedback after 3 seconds
            setTimeout(() => {
                setFeedback("");
            }, 3000);

        } catch (error) {
            console.error("Error processing the offer:", error);
            setFeedback("Error sending offer. Please try again.");  // Provide error feedback
        }
    };

    const handleAcceptOffer = async (offer) => {
        try {
            await GameJobService.modifyOffer(offer.gamejobId, offer.id, { ...offer, status: 'ACCEPTED' });

            // Define success and cancel URLs
            const successUrl = `${window.location.origin}/payment-success`;
            const cancelUrl = `${window.location.origin}/payment-cancelled`;

            // Initiate payment and get sessionId
            // Pass the offer price to the payment initiation function
            const sessionId = await StripeService.initiatePayment(userId, offer.gamejobId, successUrl, cancelUrl, offer.offerPrice);

            // Redirect to Stripe checkout
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({
                sessionId: sessionId.id,
            });

        } catch (error) {
            console.error("Error accepting the offer and initiating payment:", error);
        }
    };


    const handleRejectOffer = async (offer) => {
        try {
            await GameJobService.modifyOffer(offer.gamejobId, offer.id, { ...offer, status: 'REJECTED' });
            // Refresh the received offers or remove the rejected offer from state
            setReceivedOffers(prevOffers => prevOffers.map(o => o.id === offer.id ? { ...o, status: 'REJECTED' } : o));
        } catch (error) {
            console.error("Error rejecting the offer:", error);
        }
    }

    return (
        <>
            <Card className="conversation-card">
                <Card.Header>{conversation.topic}</Card.Header>
                <Card.Body className="conversation-body">
                    <div className="profile-images">
                        {loggedInUserProfile && (
                            <Link to={`/profile/${loggedInUserProfile.id}`}>
                                <img src={getDefaultImageUrl(loggedInUserProfile.imageUrl)} style={{ width: '50px', height: '50px', borderRadius: '50%', marginBottom: '16px' }} alt={loggedInUserProfile.firstName} />
                            </Link>
                        )}
                        {otherUser && (
                            <Link to={`/profile/${otherUser.id}`}>
                                <img src={getDefaultImageUrl(otherUser.imageUrl)} style={{ width: '50px', height: '50px', borderRadius: '50%', marginBottom: '16px' }} alt={otherUser.firstName} />
                            </Link>
                        )}
                    </div>
                    {messages.map((message, index) => {
                        const isSameSenderAsPrevious = index > 0 && messages[index - 1].senderId === message.senderId;

                        if (!message.senderId) {
                            return null;
                        }

                        const messageAlignmentClass = Number(message.senderId) === Number(userId) ? 'message-left' : 'message-right';

                        return (
                            <div
                                ref={messagesEndRef}
                                key={`${message.senderId}-${index}`} // Ensure uniqueness
                                className={`message ${messageAlignmentClass}`}
                            >
                                <div className="message-content">
                                    {!isSameSenderAsPrevious && message.senderId !== userId && <strong>{`${message.senderFirstName}:      `}</strong>}
                                    {message.msg}
                                </div>
                            </div>
                        );
                    })}

                    {receivedOffers.length > 0 && (
                        <div className="received-offers-section">
                            <h5>Received Offers:</h5>
                            {receivedOffers.map((offer, index) => (
                                <div key={index} className="offer-item">
                                    <p style={{ fontWeight: '500', fontFamily: 'Georgia' }}>
                                        Offer: <span className="price" style={{ marginLeft: '20px' }}>${offer.offerPrice}</span> from {otherUser.firstName}
                                    </p>
                                    {offer.status === 'COUNTERED' && (
                                        <>
                                            <Button variant="danger" onClick={() => handleRejectOffer(offer)}>Reject</Button>
                                            <Button variant="success" onClick={() => handleAcceptOffer(offer)}>Accept</Button>
                                        </>
                                    )}
                                    {offer.status !== 'COUNTERED' && (
                                        <p>Status: {offer.status}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                </Card.Body>
                {feedback && <p><b style={{ marginLeft: "20px" }}>{feedback}</b></p>}

                <Card.Footer>
                    <Row>
                        {/* Column for message input and Send button */}
                        <Col xs={12} md={8} className="d-flex flex-column align-items-start">
                            <Form.Control as="textarea" rows={3} placeholder="Type a message" value={message} onChange={e => setMessage(e.target.value)} style={{ width: '100%', marginBottom: '10px' }}/>
                            <Button variant="primary" id="conversation-list-send-btn" onClick={handleSend}>Send</Button>
                        </Col>

                        {/* Column for Custom Offer button and related UI */}
                        <Col xs={12} md={4} className="d-flex flex-column align-items-end">
                            {services.some(service => Number(service.sellerId) === Number(userId)) && !currentOffer && !showOfferUI && (
                                <Button variant="primary" onClick={() => setShowOfferUI(true)} style={{ width: '100%', marginBottom: '10px' }}>
                                    Custom Offer
                                </Button>
                            )}

                            {services.some(service => Number(service.sellerId) === Number(userId)) && !currentOffer && showOfferUI && (
                                <>
                                    <Dropdown style={{ width: '100%', marginBottom: '10px' }} onSelect={handleServiceSelection}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%' }}>
                                            {selectedService?.name || "Service?"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ width: '100%' }}>
                                            {services.map(service => (
                                                <Dropdown.Item key={service.id} eventKey={JSON.stringify(service)}>
                                                    {service.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form.Control
                                        type="number"
                                        placeholder="Send custom offer ($)"
                                        value={offerPrice}
                                        min="2.5"
                                        onChange={e => setOfferPrice(e.target.value)}
                                        style={{ width: '100%', marginBottom: '10px' }}
                                    />
                                    <Button variant="secondary" onClick={handleSendOffer} style={{ width: '100%' }}>Send Offer</Button>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card.Footer>

            </Card>

            <Modal show={showModal} onHide={() => setSelectedService(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Offer for {selectedService?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="number" placeholder="Enter your offer ($)" value={offerPrice}
                                  onChange={e => setOfferPrice(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSelectedService(null)}>Close</Button>
                    <Button variant="primary" onClick={handleSendOffer}>Send Offer</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConversationDetail;
