import TermsAndConditions from "./TermsAndConditions";
import AuthService from "../services/AuthService";
import Header from "../components/Header";
import LPHeader from "../components/LPHeader";
import React from "react";
const TermsAndConditionsPage = () => {
    const isLoggedIn = AuthService.isLoggedIn();
    return (
        <>
            { isLoggedIn ? <Header /> : <LPHeader /> }
            <TermsAndConditions />
        </>
    )
}
export default TermsAndConditionsPage;