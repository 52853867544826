// UserProfile.js
import React, {useState, useRef, useEffect} from 'react';
import { Card, Col, Row, Container, Button, Form } from "react-bootstrap";
import UserService from '../services/UserService';
import AuthService from '../services/AuthService';
import GameJobService from "../services/GameJobService";
import ServiceCard from "./ServiceCard";
import {containsBannedWords, getDefaultImageUrl} from "../utils/Helper";
import "../css/Profile.css";
import Select from 'react-select';
import VisualButton from "./VisualButton";
import { Pencil } from "react-bootstrap-icons";
const UserProfile = ({ user }) => {
    const [editMode, setEditMode] = useState(false);
    const [firstName, setFirstName] = useState(user.firstName || "");
    const [lastName, setLastName] = useState(user.lastName || "");
    const [location, setLocation] = useState(user.location || "");
    const [bio, setBio] = useState(user.bio || "");
    const [previewImage, setPreviewImage] = useState(null);
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const fileRef = useRef();
    const [bioSnapshot, setBioSnapshot] = useState(user.snapshot || "");

    const countries = [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua & Deps',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Central African Rep',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo',
        'Congo {Democratic Rep}',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'East Timor',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland {Republic}',
        'Israel',
        'Italy',
        'Ivory Coast',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'Korea North',
        'Korea South',
        'Kosovo',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar, {Burma}',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russian Federation',
        'Rwanda',
        'St Kitts & Nevis',
        'St Lucia',
        'Saint Vincent & the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome & Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Trinidad & Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Vatican City',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbabwe'
    ].map(country => ({ value: country, label: country }));


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                if (img.width <= 2000 && img.height <= 2000) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setPreviewImage(reader.result);
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert("The image dimensions should be less than or equal to 2000*2000 pixels.");
                }
            };
        }
    };

    const toggleEditMode = () => {
        // When entering edit mode, ensure states are set to user's current data
        if (!editMode) {
            setFirstName(user.firstName || '');
            setLastName(user.lastName || '');
            setLocation(user.location || '');
            setBioSnapshot(user.bioSnapshot || 'Your catchy tagline'); // Set to user's bioSnapshot or default text
            setBio(user.bio || '');
        }
        setEditMode(!editMode);
    };

    const saveProfile = () => {
        if (!containsBannedWords(firstName)
            && !containsBannedWords(lastName)
            && !containsBannedWords(location)
            && !containsBannedWords(bio)
            ) {

            const profileDTO = {
                firstName: firstName || null,
                lastName: lastName || null,
                location: location || null,
                bio: bio || null,
                bioSnapshot: bioSnapshot || null
            };

            const uploadImageIfNeeded = () => {
                if (fileRef.current && fileRef.current.files[0]) {
                    return UserService.uploadUserProfileImage(user.id, fileRef.current.files[0]);
                } else {
                    return Promise.resolve();
                }
            };

            UserService.editUserProfileInformation(user.id, profileDTO)
                .then(updatedUser => {
                    // Assuming updatedUser is the response from the server with the updated details
                    setFirstName(updatedUser.firstName);
                    setLastName(updatedUser.lastName);
                    setLocation(updatedUser.location);
                    setBio(updatedUser.bio);
                    return uploadImageIfNeeded(); // Chain the promises
                })
                .then(() => {
                    setEditMode(false);
                    window.location.reload(); // Here's where the page is refreshed
                })
                .catch(console.error)
        }
        else {
                setErrorMessage("Ensure the price is above $2.5 and no fields contain banned words.");
            }

    };
    const [services, setServices] = useState([]);

    useEffect(() => {
        if (user.role === 'seller') {
            GameJobService.getServicesBySeller(user.id)
                .then(data => {
                    setServices(data);
                })
                .catch(err => {
                    console.error(err);
                });
        }
        // Initialize state variables with user's current data
        setFirstName(user.firstName || '');
        setLastName(user.lastName || '');
        setLocation(user.location || '');
        setBioSnapshot(user.bioSnapshot || 'Your catchy tagline'); // Use user's bioSnapshot or default text
        setBio(user.bio || '');
    }, [user]); // Re-run this effect if user data changes


    return (
        <Container className="profile-container" style={{ marginBottom: '100px', paddingBottom: '100px' }}>
            <Row className="justify-content-md-center">
                <Col xs={12} md={10}>
                    <Card className="profile-card">
                        <Card.Body>
                            <Row style={{ marginTop: editMode ? '30px' : '40px' }}>
                                <Col xs={12} md={4} style={{ marginTop: '56px' }} className="text-center image-column">
                                    <img
                                        src={previewImage || getDefaultImageUrl(user.imageUrl)}
                                        alt={`${user.firstName} ${user.lastName}`}
                                        className="rounded-circle profile-img single-line-text"
                                        style={{ marginBottom: '10px !important' }}
                                    />
                                    {editMode &&
                                        <div className="image-upload">
                                            <label htmlFor="profileImage" className="btn btn-primary mt-2">Upload Image</label>
                                            <input id="profileImage" type="file" ref={fileRef} style={{display: 'none'}} onChange={handleImageChange} />
                                        </div>
                                    }
                                </Col>
                                <Col xs={12} md={8} className="profile-info" id='name-mt' style={{marginTop: '30px'}}>

                                    <Form noValidate validated={validated} onSubmit={e => {
                                        const form = e.currentTarget;
                                        if (form.checkValidity() === false) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }
                                        setValidated(true);
                                    }}>

                                        {editMode ?
                                            <>
                                                {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                                                <Form.Group className="form-group-mgn-top">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" placeholder="First Name" maxLength="200" value={firstName || ''} onChange={(e) => setFirstName(e.target.value)} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please ensure your first name is less than 200 characters.
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="form-group-mgn-top">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Last Name" maxLength="200" value={lastName || ''} onChange={(e) => setLastName(e.target.value)} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please ensure your last name is less than 200 characters.
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="form-group-mgn-top">
                                                    <Form.Label>Snapshot</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Your catchy tagline"
                                                        maxLength="106"
                                                        value={bioSnapshot || ''} // Bind to bioSnapshot state
                                                        onChange={(e) => setBioSnapshot(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please ensure your snapshot is less than 120 characters.
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="form-group-mgn-top">
                                                    <Form.Label>Location</Form.Label>
                                                    <Select
                                                        options={countries}
                                                        onChange={selectedOption => setLocation(selectedOption.value)}
                                                        value={countries.find(country => country.value === location)}
                                                        placeholder="Select or type to search..."
                                                    />
                                                </Form.Group>

                                                <Form.Group className="form-group-mgn-top">
                                                    <Form.Label>Bio</Form.Label>
                                                    <Form.Control as="textarea" maxLength="200" value={bio || ''} onChange={(e) => setBio(e.target.value)} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please ensure your bio is less than 200 characters.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </>
                                            :
                                            <>
                                                {!editMode && (
                                                    <>
                                                        <Card.Text className="single-line-text profile-field profile-name margin-top-profile">
                                                            {user.firstName} {user.lastName}
                                                        </Card.Text>
                                                        <Card.Text className="margin-field" style={{ marginLeft: '10px', fontSize: '18px', fontWeight: '500' }}>
                                                            {user.location && user.location !== "Not Set" ? user.location : "No location set"}
                                                        </Card.Text>
                                                        <Card.Text className="margin-field profile-snapshot" style={{ textAlign: 'left', marginLeft: '10px', fontSize: '18px', fontStyle: 'italic' }}>
                                                            {user.bioSnapshot || "No snapshot available"}
                                                        </Card.Text>
                                                        <Card.Text className="margin-field profile-bio" style={{ textAlign: 'left', marginLeft: '10px' }}>
                                                            {user.bio || "No bio available"}
                                                        </Card.Text>
                                                    </>
                                                )}

                                            </>
                                        }
                                    </Form>
                                </Col>
                            </Row>
                            {AuthService.getUserId() === String(user.id) &&
                                <div className="margin-top-profile icon-buttons edit-button-right">
                                    <div className="icon-button-container">
                                        <VisualButton
                                            icon={Pencil}
                                            text={editMode ? "Save" : "Edit"}
                                            variant="primary"
                                            onClick={editMode ? saveProfile : toggleEditMode}
                                        />
                                    </div>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                    {user.role === 'seller' &&
                        <div className="services-container">
                            <h3 className="services-title">My Services</h3>
                            {services.map(service => (
                                <ServiceCard key={service.id} service={service} />
                            ))}
                        </div>
                    }
                </Col>
            </Row>
        </Container>
    );

};

export default UserProfile;
