import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import ConversationListItem from './ConversationListItem';

const ConversationList = ({ conversations, onSelectConversation, userId }) => {
    if (!conversations) {
        return null;
    }

    return (
        <ListGroup>
            {conversations.map((conversation, index) => (
                <ConversationListItem
                    key={`${conversation.firstName}-${conversation.lastName}-${index}`}
                    conversation={conversation}
                    onSelect={() => onSelectConversation(conversation)}
                    userId={userId}
                />
            ))}
        </ListGroup>
    );
};

export default ConversationList;