import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Container, Pagination } from "react-bootstrap";
import GameJobService from "../services/GameJobService";
import { getDefaultImageUrl } from "../utils/Helper";
import "../css/Profile.css";
import { LinkContainer } from "react-router-bootstrap";
import UserServicesSmallServiceCard from "./UserServicesSmallServiceCard";
import "../css/UserServicesSmallServiceCard.css";

const UserProfileServices = ({ user }) => {
    const [sellerServices, setSellerServices] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(4); // Adjust the number of items per page as needed

    useEffect(() => {
        const fetchUserServices = async () => {
            try {
                const data = await GameJobService.getServicesBySeller(user.id);
                setSellerServices(data);
            } catch (err) {
                console.error(err);
                setSellerServices([]); // Reset the services in case of an error
            }
        };

        fetchUserServices();

    }, [user]); // Depend on user data

    // Pagination logic
    const paginate = (items, pageNumber) => {
        const startIndex = (pageNumber - 1) * itemsPerPage;
        return items.slice(startIndex, startIndex + itemsPerPage);
    };

    // Updated Pagination Render Logic
    const renderPagination = (data, currentPage, setCurrentPageFunction) => {
        if (!data) return null; // Check if data is not null

        const totalPages = Math.ceil(data.length / itemsPerPage);
        return (
            <Pagination className="justify-content-center">
                {[...Array(totalPages).keys()].map(number => (
                    <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => setCurrentPageFunction(number + 1)}>
                        {number + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        );
    };

    return (
        <Container className="profile-container" style={{ paddingBottom: '100px' }}>
            <Row className="justify-content-md-center">
                <Col xs={12} md={10}>
                    <Card>
                        <Card.Body>
                            {/* Top Section: Profile Image on Left, Name & Details on Right */}
                            <Row>
                                <Col xs={12} md={4} className="d-flex flex-column align-items-center">
                                    <img
                                        src={getDefaultImageUrl(user.imageUrl)}
                                        alt={`${user.firstName} ${user.lastName}`}
                                        className="user-services-profile-image"
                                    />
                                </Col>
                                <Col xs={12} md={8} style={{ paddingTop: '40px' }} className="d-flex flex-column justify-content-center">
                                    <LinkContainer to={`/profile/${user.id}`}>
                                        <h3 >{user.firstName} {user.lastName}</h3>
                                    </LinkContainer>
                                    {user.location && <Card.Text style={{ fontWeight: 'bold', marginTop: '10px' }}>{user.location}</Card.Text>}
                                    {user.bioSnapshot && <Card.Text style={{ fontStyle: 'italic', marginTop: '10px' }}>{user.bioSnapshot}</Card.Text>}
                                    {user.bio && <Card.Text style={{ marginTop: '10px', marginBottom: '10px' }}>{user.bio}</Card.Text>}
                                </Col>
                            </Row>

                            {/* Services Section */}
                            <Row className="row-services align-items-center justify-content-center">
                                <Col xs={12} className="small-screen-center">
                                    <h2 style={{ marginTop: '10px' }}>My Services</h2>
                                </Col>
                                <Col xs={12}>
                                    <Row>
                                        {sellerServices && sellerServices.length > 0 ? (
                                            paginate(sellerServices, currentPage).map(service => (
                                                <Col xs={12} sm={6} md={6} lg={3} key={service.id}>
                                                    <UserServicesSmallServiceCard service={service} />
                                                </Col>
                                            ))
                                        ) : (
                                            <Col>
                                                <p>No services to display</p>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                            {renderPagination(sellerServices, currentPage, setCurrentPage)}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
};

export default UserProfileServices;
