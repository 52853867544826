import React, {useState} from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import '../css/BecomeASeller.css';
import { useNavigate } from 'react-router-dom';
import BecomeSellerButton from '../components/BecomeSellerButton';

import minecraft from '../assets/icons8-minecraft-logo.svg'
import leagueOfLegends from '../assets/LoL_icon.svg.png'
import rocketLeague from '../assets/rocket-league-next-gen.jpg'
import valorant from '../assets/icons8-valorant.svg'
import fortnite from '../assets/fotrnite.jpg'
import rust from '../assets/icons8-rust.svg'
import counterstrike from '../assets/counter-strike-global-offensive-soldier-graphics-background-wallpaper.jpg'

import digital from '../assets/vg.jpg'
import rpg from '../assets/clint-bustrillos-X-A-LJVAhzk-unsplash.png'
import tableTop from '../assets/denis-volkov-Q91-N8IsEoE-unsplash.jpg'
import card from '../assets/klim-musalimov-F7xRdXXvx9c-unsplash.jpg'

import sellExperiences from '../assets/pexels-tima-miroshnichenko-6498791.jpg'
import coaching from '../assets/chess-icon-11284-Windows.ico'
import gamingVideoEditing from '../assets/chess-icon-11284-Windows.ico'
import gameTesting from '../assets/chess-icon-11284-Windows.ico'
import gameDevelopment from '../assets/chess-icon-11284-Windows.ico'
import UserService from "../services/UserService";

const BecomeASeller = () => {

    const navigate = useNavigate();

    const gameImages = [minecraft, leagueOfLegends, rocketLeague, valorant, fortnite, rust, counterstrike];
    const gameCategoriesImages = [digital, rpg, tableTop, card];
    const gameCategoriesNames = ["Digital", "RPG", "Table Top", "Card"];

    // Add service images to a new array
    const serviceImages = [coaching, gamingVideoEditing, gameDevelopment, gameTesting];

    // define isHovered state as an object
    const [isHovered, setIsHovered] = useState({index: null, type: null});

    // adjust handler functions to also consider the type (category, game, or service)
    const handleMouseEnter = (index, type) => {
        setIsHovered({index, type});
    };

    const handleMouseLeave = () => {
        setIsHovered({index: null, type: null});
    };


    const becomeASeller = async () => {
        const userId = localStorage.getItem("userId")

        try {
            // assuming updateRoleMapping takes the userId and the new role as parameters
            await UserService.updateRoleMapping(userId, {"role": "SELLER"});
            localStorage.setItem("role", "SELLER")
            navigate('/selling');
        } catch (error) {
            console.error("Failed to update user role: ", error);
        }
    };

    return (
        <div style={{ marginBottom: '40px' }}>
            <div className="background-image">
                <div className="centered-text">
                    <h1>Monetize your passion</h1>
                    <p style={{ fontSize: '18px' }}>You've got the talent. We've got the tools to help you earn.</p>
                    <BecomeSellerButton onClick={becomeASeller} />
                </div>
            </div>

            <Container className="mt-5">
                <h2 className="text-center">How it works</h2>

                <Row>
                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>1. Create service(s)</Card.Title>
                                <Card.Text>Register for free and showcase your unique service(s) to our community of buyers.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>2. Fulfill your service</Card.Title>
                                <Card.Text>Understand your buyer's needs and deliver work that satisfies their expectations.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>3. Get paid</Card.Title>
                                <Card.Text>Upon successful completion of the order, receive your payment promptly.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-5">
                <h2 className="text-center">Game Categories</h2>

                <Row className="game-category-img subcategories">
                    {gameCategoriesImages.map((image, index) => (
                        <Col xs={6} md={3} style={{position: 'relative'}} key={index}>
                            <img
                                className={`subcat-img ${isHovered.type === 'game-category' && isHovered.index !== index ? 'not-hovered' : ''}`}
                                src={image}
                                alt="Subcategory"
                                onMouseEnter={() => handleMouseEnter(index, 'game-category')}
                                onMouseLeave={handleMouseLeave}
                            />
                            <div className="image-text">{gameCategoriesNames[index]}</div>
                        </Col>
                    ))}
                </Row>
            </Container>

            {/*

                    <Container className="mt-5">
                        <h2 className="text-center">Service Inspiration</h2>

                        <Row className="service-img subcategories">
                            {serviceImages.map((image, index) => (
                                <Col xs={6} md={3} style={{position: 'relative'}} key={index}>
                                    <img
                                        className={`subcat-img ${isHovered.type === 'service' && isHovered.index !== index ? 'not-hovered' : ''}`}
                                        src={image}
                                        alt="Subcategory"
                                        onMouseEnter={() => handleMouseEnter(index, 'service')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Container>
            */}
            <Container className="mt-5">
                <h2 className="text-center">Sell Experiences</h2>
                <Row>
                    <Col xs={12} md={6} className="text-center" style={{ marginBottom: "0", height: "auto" }}>
                        <img src={sellExperiences} alt="Experiences" style={{ width: '100%', height: "auto", maxHeight: "500px", marginBottom: "0" }} />
                    </Col>
                    <Col xs={12} md={6} style={{ marginTop: "0"}}>
                        <h4 style={{ marginTop: "20px"}}>Get paid to play</h4>
                        <p>
                            Imagine being able to <strong>transform your passion for gaming</strong> into a profitable venture by offering gaming experiences.
                            If you're a gamer gifted with deep knowledge, a personable demeanor, and a fair amount of prowess, have you considered <em>turning your passion into profit</em>?

                            What about getting compensated to play in a cooperative gaming scenario? For instance, you could accompany a novice solo player through a Rust wipe,
                            <strong> transforming</strong> what might have otherwise been a challenging experience into a more <em>enjoyable journey</em>.
                        </p>
                        <p>
                            This is not just about playing the game—it's about <strong>sharing your expertise</strong>, enhancing others' experiences, and getting paid in the process.
                            Or maybe, you're interested in farming on an MMO or exploring <em>countless other gaming opportunities</em>?
                            Ready to <strong>evolve your gaming hobby into a profitable venture</strong>?
                            Dive in now and discover a world of opportunities waiting for you in the gaming realm!
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-5">
                <h2 className="text-center">Digital Games</h2>

                <Row>
                    {gameImages.map((image, index) => (
                        <Col xs={6} md={3} style={{position: 'relative'}} key={index}>
                            <img
                                className={`subcat-img ${isHovered.type === 'game' && isHovered.index !== index ? 'not-hovered' : ''}`}
                                src={image}
                                alt="Subcategory"
                                onMouseEnter={() => handleMouseEnter(index, 'game')}
                                onMouseLeave={handleMouseLeave}
                            />
                        </Col>
                    ))}
                    <Col xs={6} md={3}>
                        <div className="not-your-vibe-card">
                            <div className="text-center">
                                <p style={{ fontFamily: 'Roboto' }}>Not your vibe?</p>
                                <BecomeSellerButton onClick={becomeASeller} />
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    );
};

export default BecomeASeller;

