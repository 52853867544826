import Login from '../components/LoginForm';
import LPHeader from "../components/LPHeader";
import Header from "../components/Header";
import React from "react";
import AuthService from "../services/AuthService";
import background from "../assets/immg2.png";
const LoginPage = () => {

    const isLoggedIn = AuthService.isLoggedIn();

    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        }}>
            { isLoggedIn ? <Header /> : <LPHeader /> }
            <Login />
        </div>
    );

};

export default LoginPage;