// MessageForm.js
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import userService from "../services/UserService";
import AuthService from "../services/AuthService";
import FormLabel from '../components/FormLabel';
import '../css/MessageForm.css'


const MessageForm = ({ senderId }) => {
    const [recipientId, setRecipientId] = useState('');
    const [recipientSearch, setRecipientSearch] = useState('');
    const [topic, setTopic] = useState('');
    const [msg, setMsg] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const loggedInUserId = AuthService.getUserId();

    const handleRecipientSearch = async (event) => {
        setRecipientSearch(event.target.value);
        // Fetch users based on the name typed in the search box
        const response = await userService.searchUsersByName(event.target.value);

        // Filter out the current user (sender) from the search results
        const filteredResults = response.filter(user => Number(user.id) !== Number(loggedInUserId));
        setSearchResults(filteredResults);

        setShowResults(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Create the message object
        const messageDTO = {
            senderId: senderId,
            recipientId: recipientId,
            topic: topic,
            msg: msg,
        };

        // Send the request
        const response = await userService.saveMessageForUser(senderId, messageDTO);

        window.location.reload();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="recipientSearch">
                <FormLabel>Recipient</FormLabel>
                <Form.Control type="text" placeholder="Type name to search..." value={recipientSearch} onChange={handleRecipientSearch} />
                {
                    showResults ? (
                            searchResults.length > 0 ?
                                searchResults.map(user => (
                                    <div key={user.id} onClick={() => { setRecipientId(user.id); setRecipientSearch(user.firstName + ' ' + user.lastName); setSearchResults([]);     setShowResults(false);  // Add this line
                                    }} style={{border: '1px solid black'}}>
                                        {user.firstName + ' ' + user.lastName}
                                    </div>
                                )) :
                                <div>No users found</div>
                        ) :
                        null
                }
            </Form.Group>
            <Form.Group controlId="topic">
                <FormLabel>Topic</FormLabel>
                <Form.Control type="text" placeholder="Enter topic" value={topic} onChange={event => setTopic(event.target.value)} />
            </Form.Group>
            <Form.Group controlId="msg">
                <FormLabel>Message</FormLabel>
                <Form.Control as="textarea" rows={3} value={msg} onChange={event => setMsg(event.target.value)} />
            </Form.Group>
            <Button variant="primary" type="submit" style={{ marginTop: "12px" }}>
                Send
            </Button>
        </Form>
    );
};

export default MessageForm;
