import React from 'react';
import { Button } from 'react-bootstrap';
import { Cash } from 'react-bootstrap-icons'; // or any other icon that represents selling
import UserService from "../services/UserService";
import { useNavigate } from 'react-router-dom';
import AuthService from "../services/AuthService";
import '../css/BecomeSellerButton.css';

const BecomeSellerButton = () => {
    const navigate = useNavigate();

    const isLoggedIn = AuthService.isLoggedIn();
    const becomeASeller = async () => {
        const userId = localStorage.getItem("userId");

        if (isLoggedIn) {
            try {
                await UserService.updateRoleMapping(userId, {"role": "SELLER"});
                localStorage.setItem("role", "SELLER");
                navigate('/selling');
            } catch (error) {
                console.error("Failed to update user role: ", error);
            }
        } else {
            navigate('/sign-up');
        }
    };

    // Inline styles for the button
    const buttonStyles = {
        padding: '15px 20px',
        height: '50px',
        margin: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };


    return (
        <Button
            variant="primary"
            className="become-seller-button"
            onClick={becomeASeller}
        >
         <span className="button-text" style={{ fontFamily: 'Roboto', fontSize: '16px', fontWeight: '500' }}>Become a Seller</span>
        </Button>
    );
};

export default BecomeSellerButton;
