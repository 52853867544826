import React from 'react';
import '../css/FormLabel.css';

const FormLabel = ({ children, ...props }) => {
    return (
        <label className="form-label" {...props}>
            {children}
        </label>
    );
};

export default FormLabel;