import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/auth';
class AuthService {

    async registerUser(registerRequest) {
        try {
            const response = await axios.post(`${API_URL}/register`, registerRequest);
            return response.data;
        } catch (error) {
            console.error('AuthService error:', error);
            throw error;
        }
    }

    async loginUser(loginRequest) {
        try {
            const response = await axios.post(`${API_URL}/authenticate`, loginRequest);
            return response.data;
        } catch (error) {
            console.error('AuthService error:', error);
            throw error;
        }

    }

    isLoggedIn() {
        return !!localStorage.getItem('token');
    }

    getUserId() {
        return localStorage.getItem('userId');
    }

    async logout() {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${API_URL}/logout`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('AuthService error:', error);
            throw error;
        }
    }

}
export default new AuthService();