import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/users';

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error)
    }
);

class UserService {

    async getConversationsForUser(userId) {
        const response = await axiosInstance.get(`/${userId}/conversations`);
        return response.data;
    }

    async getMessagesForUser(userId) {
        const response = await axiosInstance.get(`/${userId}/messages`);
        return response.data;
    }

    async saveMessageForUser(userId, messageDTO) {
        const response = await axiosInstance.post(`/${userId}/messages`, messageDTO);
        return response.data;
    }

    async uploadUserProfileImage(userId, file) {
        const formData = new FormData();
        formData.append('file', file, file.name);

        return axiosInstance.post(`/${userId}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log('Upload successful:', response);
            })
            .catch(error => {
                console.error('Upload failed:', error);
            });
    }

    async getProfileInformation(userId) {
        const response = await axiosInstance.get(`/${userId}/profile`);
        return response.data;
    }

    async editUserProfileInformation(userId, profileDTO) {
        const response = await axiosInstance.post(`/${userId}/profile`, profileDTO);
        return response.data;
    }

    async updateRoleMapping(userId, updateRoleDTO) {
        const response = await axiosInstance.post(`/${userId}/role`, updateRoleDTO);
        return response.data;
    }
    async searchUsersByName(name) {
        const response = await axiosInstance.get(`/search?name=${name}`);
        return response.data;
    }

    async getSellersByFeatured() {
        const response = await axiosInstance.get(`/featured`);
        return response.data;
    }

    async getSellersByIcon() {
        const response = await axiosInstance.get(`/icons`);
        return response.data;
    }

    async getSellersByInfluencer() {
        const response = await axiosInstance.get(`/influencers`);
        return response.data;
    }

    async getSellersByFeaturedAndIsInfluencer() {
        const response = await axiosInstance.get(`/featured-influencers`);
        return response.data;
    }

    async updateIsInfluencer(userId, updateIsInfluencerDTO) {
        const response = await axiosInstance.post(`/${userId}/influencer`, updateIsInfluencerDTO);
        return response.data;
    }

    async applyForInfluencer(userId, influencerDTO) {
        const response = await axiosInstance.post(`/${userId}/influencer/apply`, influencerDTO);
        return response.data;
    }

    async getMessagesBetweenUsers(userId, id) {
        const response = await axiosInstance.get(`/messages-between/${userId}/${id}`);
        return response.data;
    }

    async getAverageReviewValueForSeller(userId) {
        const response = await axiosInstance.get(`/${userId}/seller/mean-review`);
        return response.data;
    }

}

export default new UserService();
