import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from '../components/Header';
import LPHeader from "../components/LPHeader";
import UserProfilePurchasedServiceDetails from "../components/UserProfilePurchasedServiceDetails";
import AuthService from '../services/AuthService';
import GameJobService from '../services/GameJobService';
const PurchasedServiceDetails = () => {

    const [showChatForm, setShowChatForm] = useState(false);

    const buyerId = AuthService.getUserId();

    // Purchased ServiceId
    const { purchasedServiceId } = useParams();

    const [purchasedService, setPurchasedService] = useState(null);

    // Function to fetch detailed service information
    const fetchDetailedService = async () => {
        const purchasedServiceData = await GameJobService.getPurchasedGameJobDetails(buyerId, purchasedServiceId);
        setPurchasedService(purchasedServiceData);
    };

    useEffect(() => {
        fetchDetailedService();
    }, [buyerId, purchasedServiceId]);

    const isLoggedIn = AuthService.isLoggedIn();

    return (
        <div>
            { isLoggedIn ? <Header /> : <LPHeader /> }
            <div>
                {purchasedService &&
                    <UserProfilePurchasedServiceDetails
                        detailedService={purchasedService}
                        fetchDetailedService={fetchDetailedService}
                    />
                }
            </div>
        </div>
    );
};

export default PurchasedServiceDetails;
