import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import ConversationList from "../components/ConversationList";
import ConversationDetail from "../components/ConversationDetail";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Plus } from 'react-bootstrap-icons';
import '../css/MessagingPage.css';
import UserService from '../services/UserService';
import AuthService from "../services/AuthService";
import {useParams} from "react-router-dom";
import LPHeader from "../components/LPHeader";
import MessageForm from '../components/MessageForm';
import '../css/FormLabel.css';

const MessagingPage = () => {
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const { userId } = useParams();
    const isLoggedIn = AuthService.isLoggedIn();
    const [showMessageForm, setShowMessageForm] = useState(false);

    useEffect(() => {
        const getConversations = async () => {
            try {
                const response = await UserService.getConversationsForUser(userId);
                setConversations(response);
            } catch (error) {
            }
        };

        getConversations();
    },  [userId]);

    const handleSelectConversation = (conversation) => {
        setShowMessageForm(false);
        setSelectedConversation(conversation);
    };

    return (
        <div>
            { isLoggedIn ? <Header /> : <LPHeader /> }
            <Container style={{ marginBottom: '20px', minHeight: '500px' }}>
                <Row>
                    <Col xs={12} md={4} lg={3} className="conversations-list">
                        <ConversationList
                            conversations={conversations}
                            onSelectConversation={handleSelectConversation}
                            userId={userId}
                        />
                    </Col>
                    <Col xs={12} md={8} lg={9} className="conversation-detail">
                        <Card className="text-center" style={{ marginBottom: '6px' }}>
                            <Card.Body>
                                <Card.Title style={{ fontFamily: 'Roboto' }}>Compose New Message</Card.Title>
                                <Button variant="primary" style={{ fontFamily: 'Roboto' }} onClick={() => setShowMessageForm(true)}>
                                    <Plus style={{ fontSize: '24px' }}/> Compose Message
                                </Button>
                            </Card.Body>
                        </Card>
                        {showMessageForm
                            ? <MessageForm senderId={userId} />
                            : selectedConversation
                                ? <ConversationDetail
                                    conversation={selectedConversation}
                                    userId={userId}
                                />
                                : conversations.length > 0
                                    ? null // Or any other component you want to display when there are conversations but none is selected
                                    : <Card className="text-center" style={{ marginBottom: '120px' }}>
                                        <Card.Body>
                                            <Card.Title>No Messages</Card.Title>
                                            <Card.Text>
                                                You currently have no messages.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MessagingPage;
