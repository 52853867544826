import React from 'react';
import '../css/AboutUsPage.css';
import AboutUs from "../components/AboutUs";
import LPHeader from "../components/LPHeader";
import Header from "../components/Header";
import AuthService from "../services/AuthService";

const AboutUsPage = () => {
    const isLoggedIn = AuthService.isLoggedIn();
    return (
        <div>
            { isLoggedIn ? <Header /> : <LPHeader /> }
            <AboutUs />
        </div>
    );
};

export default AboutUsPage;