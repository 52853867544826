import React from "react";
import { LinkContainer } from 'react-router-bootstrap';
import {Navbar, Nav, Image} from 'react-bootstrap';
import logo from '../assets/fanplay.svg';
import '../css/Navbar.css';

const LPNavBar = () => {
    return (
        <div>
            <Navbar expand="lg" style={{backgroundColor: "#ffffff"}}>
                <LinkContainer to="/home">
                    <Navbar.Brand className="navbar-brand-margin custom-logo">
                        <Image
                            src={logo}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="The Fanplay logo"
                        />
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        <LinkContainer to="/">
                            <Nav.Link style={{ fontFamily: 'Roboto' }} className="nav-link nav-link-text lp-navbar-extra-margin bold">Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/about">
                            <Nav.Link className="nav-link nav-link-text lp-navbar-extra-margin bold">About</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/sign-up">
                            <Nav.Link className="nav-link nav-link-text lp-navbar-extra-margin bold">Register</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/login" style={{ marginRight: "20px" }}>
                            <Nav.Link className="nav-link nav-link-margin nav-link-text lp-navbar-extra-margin bold">Login</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default LPNavBar;
