import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Slide2LargeScreen = ({ src }) => {

return (
        <div className="carousel-div">
            <img className="carousel-image" src={src} alt="Slideshow 2"/>
            <div className="carousel-caption">
                <div className="button-container">
                    <Link to="/become-a-seller">
                        <Button variant="primary" style={{ width: '220px', marginBottom: '260px' }} as="span" className="large-button">
                            Become a Seller
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Slide2LargeScreen;
