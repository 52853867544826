import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import '../App.css';
import {containsBannedWords} from "../utils/Helper";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate(); // call useNavigate hook
    const [errorMessage, setErrorMessage] = useState("");
    const handleLogin = async (event) => {
        event.preventDefault();

        if (!containsBannedWords(email)) {

            const loginRequest = {
                email,
                password,
            };

            try {
                const response = await AuthService.loginUser(loginRequest);
                localStorage.setItem('userId', response.userId);
                localStorage.setItem('token', response.access_token);
                localStorage.setItem('role', response.role);
                console.log('Login successful!');
                setTimeout(() => {  // Add delay
                    navigate('/home');  // Redirect to /home
                }, 1000);
            } catch (error) {
                console.error('Error during login:', error);
                setErrorMessage("Invalid credentials. Please try again.");
            }
        }
        else {
            setErrorMessage("Email contains banned words.");
        }
    };

    return (
        <div className="form-container login-form">
            <form onSubmit={handleLogin} className="form-box">
                {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                <h3 style={{ textAlign: 'center' }}>Sign In</h3>

                <div className="mb-3">
                    <label className="label-auth-form">Email address</label>
                    <input type="email" className="form-control" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                </div>

                <div className="mb-3">
                    <label className="label-auth-form">Password</label>
                    <input type="password" className="form-control" placeholder="Enter password" value={password} onChange={e => setPassword(e.target.value)} />
                </div>

                <div className="d-grid" style={{ marginTop: '20px' }}>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </div>
                <p className="forgot-password text-right" style={{ marginTop: '10px' }}>
                    Forgot <Link to="/reset-password">password?</Link>
                </p>
                <p className="signup-link text-center">
                    Don't have an account? <Link to="/sign-up">Sign up</Link>
                </p>
            </form>
        </div>
    );
}

export default Login;
