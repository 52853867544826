import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LPHeader from "../components/LPHeader";
import AuthService from "../services/AuthService";
import Container from 'react-bootstrap/Container';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../css/HomePage.css"
import UserService from "../services/UserService";
import UserCard from "../components/UserCard";
import {Pagination, Carousel} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGraduationCap, faWifi} from "@fortawesome/free-solid-svg-icons";
import SmallColorfulInfoSection from '../components/ColorfulInfoSectionSmall';
import '../css/PlayInfluencers.css';

const PlayInfluencersPage = () => {

    const isLoggedIn = AuthService.isLoggedIn();

    //const [icons, setIcons] = useState(null);
    const [influencers, setInfluencers] = useState(null);
    const [featuredInfluencers, setFeaturedInfluencers] = useState(null);
    // Pagination state for each section
    const [currentPageFeaturedInfluencers, setCurrentPageFeaturedInfluencers] = useState(1);
    const [currentPageIcons, setCurrentPageIcons] = useState(1);
    const [currentPageInfluencers, setCurrentPageInfluencers] = useState(1);
    const itemsPerPage = 8;

    // Function to paginate data
    const paginate = (data, currentPage) => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
    };

    useEffect(() => {
        //const loadGamingIcons = async () => {
        //    try {
        //        const iconData = await UserService.getSellersByIcon();
        //        if (!Array.isArray(iconData)) {
        // console.log("No gaming legends found.");
        //        }
        //        setIcons(iconData);
        //     } catch (error) {
        //        console.log("Error loading icons:", error);
        //    }
        //};
        const loadInfluencers = async () => {
            try {
                const influencersData = await UserService.getSellersByInfluencer();
                if (!Array.isArray(influencersData)) {
                    console.log("No influencers found.");
                }
                setInfluencers(influencersData);
            } catch (error) {
                console.log("Error loading influencers:", error);
            }
        };

        const loadFeaturedInfluencers = async () => {
            try {
                const featuredInfluencersData = await UserService.getSellersByFeaturedAndIsInfluencer();
                if (!Array.isArray(featuredInfluencersData)) {
                    console.log("No featured influencers found.");
                }
                setFeaturedInfluencers(featuredInfluencersData);
            } catch (error) {
                console.log("Error loading featured influencers:", error);
            }
        };

        //loadGamingIcons();
        loadInfluencers();
        loadFeaturedInfluencers();
    }, []);

    // Updated Pagination Render Logic
    const renderPagination = (data, currentPage, setCurrentPageFunction) => {
        if (!data) return null;

        const totalPages = Math.ceil(data.length / itemsPerPage);
        return (
            <Pagination className="justify-content-center">
                {[...Array(totalPages).keys()].map(number => (
                    <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => setCurrentPageFunction(number + 1)}>
                        {number + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        );
    };

    const IconCarousel = () => (
        <Carousel>
            <Carousel.Item>
                <div className="icon-section">
                    <p style={{ fontSize: '48px', marginBottom: "0" }}>⚔️</p>
                    <h5 style={{ fontSize: '20px', marginTop: "10px"}}>Challenge <br/> the Best</h5>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="icon-section">
                    <FontAwesomeIcon icon={faWifi} className="fa-icon" style={{ color: 'navy', fontSize: '48px', marginTop: "16px" }} />
                    <h5 style={{ fontSize: '20px', marginTop: "20px"}}>Connect & Play <br /> Influencers</h5>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="icon-section">
                    <FontAwesomeIcon icon={faGraduationCap} className="fa-icon" style={{ fontSize: '48px', marginTop: "16px"}} />
                    <h5 style={{ fontSize: '20px', marginTop: "20px"}}>Learn from <br /> the Best</h5>
                </div>
            </Carousel.Item>
        </Carousel>
    );

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
    const handleResize = () => setIsSmallScreen(window.innerWidth < 576);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <div id="homeContainer">
            {isLoggedIn ? <Header /> : <LPHeader />}
            <Container style={{marginTop: '0'}}>
                {/*}
                <div className="colorful-info-section-container">
                    <Row className="justify-content-center">
                        <SmallColorfulInfoSection/>
                    </Row>
                </div>
                */}
                {/* PLAY Gaming Legends Section */}
                <Row className="text-center mt-4" style={{ marginTop: "400px" }}>
                    {isSmallScreen ? (
                        <IconCarousel/>
                    ) : (
                        <>
                            <Col xs={12} sm={4}>
                                <div className="icon-section"
                                     style={{border: '0px black solid', marginLeft: '40px'}}>
                                    <p style={{fontSize: '48px', marginBottom: "0"}}>⚔️</p>
                                    <h5 style={{fontSize: '20px', marginTop: "10px"}}>Challenge <br/> the Best</h5>
                                </div>
                            </Col>
                            <Col xs={12} sm={4}>
                                <div className="icon-section" style={{border: '0px black solid'}}>
                                    <FontAwesomeIcon icon={faWifi} className="fa-icon"
                                                     style={{color: 'navy', fontSize: '48px', marginTop: "16px"}}/>
                                    <h5 style={{fontSize: '20px', marginTop: "20px"}}>Connect &
                                        Play <br/> Influencers</h5>
                                </div>
                            </Col>
                            <Col xs={12} sm={4}>
                                <div className="icon-section"
                                     style={{border: '0px black solid', marginRight: '40px'}}>
                                    <FontAwesomeIcon icon={faGraduationCap} className="fa-icon"
                                                     style={{fontSize: '48px', marginTop: "16px"}}/>
                                    <h5 style={{fontSize: '20px', marginTop: "20px"}}>Learn from <br/> the Best</h5>
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
                <Col md={{span: 8, offset: 2}}
                     style={{display: 'flex', flexDirection: 'column', justifyContent: 'start'}}>
                    <h2 style={{
                        color: 'black',
                        textAlign: 'center',
                        fontSize: '24px',
                        marginTop: '40px',
                        padding: '10px 0'
                    }}>Play Gaming Legends</h2>
                    {isLoggedIn ? (
                        <>
                            <p style={{
                                hyphens: 'auto',
                                font: 'Helvetica',
                                color: 'black',
                                textAlign: 'center',
                                fontSize: '18px',
                                margin: '0 auto',
                                padding: '0 20px'
                            }}>
                                Our platform is not just about watching the best – it's about playing 🎮 with them,
                                learning 🧠 from them, and being part of the action 🎬.
                                <span style={{
                                    font: 'Helvetica',
                                    color: 'blue',
                                    textAlign: 'center',
                                    fontSize: '16px'
                                }}>
                                <br/>Note: A 20% transaction fee is applied to all influencer experiences.
                                </span>
                            </p>
                        </>
                    ) : (
                        <>
                            <p style={{
                                hyphens: 'auto',
                                font: 'Helvetica',
                                color: 'black',
                                textAlign: 'center',
                                fontSize: '18px',
                                margin: '0 auto',
                                padding: '0 20px'
                            }}>
                                Our platform is not just about watching the best – it's about playing 🎮 with them,
                                learning 🧠 from them, and being part of the action 🎬. <br/><br/> Step
                                into a world 🌍 where gaming with the elites is just a click away 👉💻.
                                <span style={{
                                    font: 'Helvetica',
                                    color: 'blue',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    margin: '20px auto',
                                    padding: '0 20px'
                                }}>
                                        <br />
                                Note: A 20% transaction fee is applied to all influencer experiences.
                                </span>
                            </p>
                        </>
                    )}
                </Col>
            </Container>
            <Container>
                <h2 style={{ marginBottom: '0 !important' }}>Featured Influencers</h2>
                <div>
                    <Row>
                        {featuredInfluencers ? paginate(featuredInfluencers, currentPageFeaturedInfluencers).map(user => (
                            <Col xs={6} sm={6} md={4} lg={3} key={user.id}>
                                <UserCard user={user} />
                            </Col>
                        )) : <p>Loading...</p>}
                    </Row>
                </div>
                {renderPagination(featuredInfluencers, currentPageFeaturedInfluencers, setCurrentPageFeaturedInfluencers)}

                {/*
                <h2 className="home-headers mt-4 mb-3">Gaming Icons</h2>
                <Row className="home-row g-4">
                    {icons ? paginate(icons, currentPageIcons).map(user => (
                        <Col xs={12} sm={6} md={4} lg={3} key={user.id}>
                            <UserCard user={user} />
                        </Col>
                    )) : <p>Loading...</p>}
                </Row>
                {renderPagination(icons, currentPageIcons, setCurrentPageIcons)}
                */}

                <h2 className="home-headers" style={{ marginBottom: '0 !important' }}>Influencers</h2>
                <Row className="home-row" style={{ marginBottom: '10px !important' }}>
                    {influencers ? paginate(influencers, currentPageInfluencers).map(user => (
                        <Col xs={6} sm={6} md={4} lg={3} key={user.id}>
                            <UserCard user={user} />
                        </Col>
                    )) : <p>Loading...</p>}
                </Row>
                {renderPagination(influencers, currentPageInfluencers, setCurrentPageInfluencers)}
            </Container>
        </div>
    );
};

export default PlayInfluencersPage;