import React, { useState, useEffect } from 'react';
import '../css/LPDistributor.css';
import { useNavigate } from 'react-router-dom';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSync, faTrophy } from '@fortawesome/free-solid-svg-icons';

const LPDistributorPage = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 674);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 674);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navigateToHome = () => navigate('/home');
    const navigateToPlayInfluencers = () => navigate('/play-influencers');

    return (
        <div className={isMobile ? "page mobile" : "page"}>
            <div className="square" onClick={navigateToHome}>
                <div className="icon">
                    <FontAwesomeIcon icon={faSync} />
                </div>
                <div className="text">Services Marketplace</div>
            </div>
            <div className="square" onClick={navigateToPlayInfluencers}>
                <div className="icon">
                    <FontAwesomeIcon icon={faTrophy} />
                </div>
                <div className="text">Play your Gaming Icons</div>
            </div>
        </div>
    );
};

export default LPDistributorPage;
