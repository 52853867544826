import defaultProfilePhoto from '../assets/default_profile_photo.png';
import defaultServiceImage from '../assets/slidemobile2.png';

export const getDefaultImageUrl = (imageUrl) => {
    return imageUrl || defaultProfilePhoto;
};

export const getDefaultServiceImageUrl = (imageUrl) => {
    return imageUrl || defaultServiceImage;
};


const bannedWords = ["shit", "fuck", "fucknugget", "nigga", "nigger", "bastard", "dick"];

export function containsBannedWords(input) {
    if (!input) return false;
    for (let word of bannedWords) {
        if (input.toLowerCase().includes(word)) {
            return true;
        }
    }
    return false;
};