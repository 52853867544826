import React from 'react';
import AppRouter from './AppRouter';

import './App.css';
import Footer from "./components/Footer";

const App = () => {

    return (
        <div className="app-container">
            <div className="content">
                <AppRouter />
            </div>
            <Footer />
        </div>
    );
};

export default App;
