import React from 'react';
import Header from '../components/Header';
import AuthService from '../services/AuthService';
import LPHeader from "../components/LPHeader";
import BecomeASeller from "../components/BecomeASeller";

const BecomeASellerPage = () => {
    const isLoggedIn = AuthService.isLoggedIn();

    return (
        <div>
            { isLoggedIn ? <Header /> : <LPHeader /> }

            <BecomeASeller/>
        </div>
    );
}

export default BecomeASellerPage;
