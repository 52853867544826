import {Col, Container, Image, Row} from "react-bootstrap";
import teamPhoto from '../assets/fanplay.svg';
import React from "react";
const AboutUs = () => {

    return (
        <Container className="about-us">
            <Row className="align-items-start about-us-body">
                <Col xs={12} md={6}>
                    <Image src={teamPhoto} style={{ marginTop:"10px"}}fluid/>
                </Col>
                <Col xs={12} md={6}>
                    <h4><b>Our Vision</b></h4>
                    <p>
                        <b>Fanplay</b> introduces a revolutionary new offering in the world of gaming: for the first time ever, challenge or play cooperatively with your favourite streamers, influencers and pro players! Our <b>vision</b> is to redefine the meaning of interaction between fans and their beloved gaming icons. Imagine a realm where you're not just a spectator but an active participant, engaging in thrilling challenges or teaming up with the very streamers, influencers, and professional players you admire.
                    </p>
                    <h4><b>Empowering the Gaming Community</b></h4>
                    <p><b>Fanplay</b> aspires to be the definitive <b>services & experiences marketplace</b> dedicated exclusively to <b>gaming</b>.
                        We want to make it easier for people to purchase and sell services that benefit the <b>gaming</b> community.
                        World builders, game testers, artists, designers, coaches, farmers, guides, editors, and programmers: We welcome you, and hope to foster a community of unified individuals with a mutual passion for gaming.
                        <b> Gaming</b> is more than just a pastime-it's a passion, a culture, a community. From world builders to friendly game guides willing to protect and teach the vulnerable, we're building a hub for everyone who loves <b>gaming</b> as much as we do.
                    </p>
                    <h4><b>A First Offering</b></h4>
                    <p>This iteration of <b>Fanplay</b> is an <b>MVP</b>, and while we are proud of the quality, please be thoughtful of this while using the site. In this initial phase, we offer full support for purchasing services, managing the progression and delivery of services, leaving reviews, and future monitoring of past transactions. These features lay the foundation for what Fanplay will grow into.</p>
                    <p>In our journey to make <b>Fanplay</b> the ultimate platform, we need user feedback, and would <b><i>love</i></b> to hear from you.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutUs;