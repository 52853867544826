import React from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import AuthService from "../services/AuthService";
import Header from "../components/Header";
import LPHeader from "../components/LPHeader";

const isLoggedIn = AuthService.isLoggedIn();
const PasswordResetInfoPage = () => {
    return (
        <div>
        {isLoggedIn ? <Header /> : <LPHeader />}
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <Card style={{ marginBottom: '100px', maxWidth: "600px", width: "100%", padding: "20px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                <Card.Body>
                    <Card.Title className="text-center mb-4" style={{ fontSize: "24px", fontWeight: "bold" }}>Need Help with Your Password?</Card.Title>
                    <Card.Text style={{ fontSize: "16px", lineHeight: "1.6" }}>
                        We understand that sometimes you might need assistance with your account. If you're unable to reset your password through the usual process, don't worry! We're here to help.
                    </Card.Text>
                    <Card.Text style={{ fontSize: "16px", lineHeight: "1.6" }}>
                        Please reach out to us directly at <a href="mailto:contact@fanplay.com">contact@fanplay.com</a> with your request. To ensure a quick and secure process, include some basic information about your account (like your sign-up date or any services associated with your account). We'll get back to you as soon as possible with the next steps.
                    </Card.Text>
                    <div className="text-center">
                        <Button href="mailto:contact@fanplay.com" variant="primary" style={{ marginTop: "20px" }}>Email Us</Button>
                    </div>
                </Card.Body>
            </Card>
        </Container>
        </div>
    );
};

export default PasswordResetInfoPage;

