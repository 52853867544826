import React, {useEffect, useState} from 'react';
import { FaStar, FaPlus } from 'react-icons/fa';
import Header from "../components/Header";
import LPHeader from "../components/LPHeader";
import AuthService from "../services/AuthService";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import GameJobService from "../services/GameJobService";

const CompleteOrderPage = () => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(null);
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [review, setReview] = useState("");
    const isLoggedIn = AuthService.isLoggedIn();
    const params = useParams();
    const purchasedGameJobId = parseInt(params.purchasedServiceId, 10);
    const userId = AuthService.getUserId();
    const navigate = useNavigate();

    const location = useLocation();
    const gameJobId = location.state?.gameJobId;

    const ratingsText = ["Poor", "Fair", "Good", "Very Good", "Excellent"];



    useEffect(() => {
        // Check if the review has already been submitted
        const reviewedServices = JSON.parse(localStorage.getItem('reviewedServices') || '[]');
        if (reviewedServices.includes(purchasedGameJobId)) {
            setShowReviewForm(false); // Hide the review form if the review has already been submitted
        }
    }, [purchasedGameJobId]);


    const submitReview = async (e) => {
        e.preventDefault();

        try {
            const reviewDTO = {
                "purchasedGameJobId": purchasedGameJobId,
                "rating": rating,
                "comment": review
            };

            await GameJobService.addGameJobReview(reviewDTO, gameJobId, userId);

            // Set the reviewed flag in local storage
            const reviewedServices = JSON.parse(localStorage.getItem('reviewedServices') || '[]');
            if (!reviewedServices.includes(purchasedGameJobId)) {
                reviewedServices.push(purchasedGameJobId);
                localStorage.setItem('reviewedServices', JSON.stringify(reviewedServices));
            }

            setShowReviewForm(false); // Optionally hide the form after submitting the review
            // Logic for successful submission
            setTimeout(() => navigate("/transactions"), 5000);
        } catch (error) {
            console.error("Error submitting review:", error);
            // Logic to handle errors
        }
    };


    return (
        <div>
            {isLoggedIn ? <Header /> : <LPHeader />}
            <div className="order-complete-page" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto', minHeight: '100vh' }}>
                <div className="order-confirmation" style={{ textAlign: 'center', marginTop: '40px' }}>
                    <h1 style={{ fontSize: '24px' }}>Thank you for your purchase!</h1>
                    <i className="checkmark-icon" style={{ fontSize: '50px', color: 'blue' }}>✔️</i>
                </div>

                <div className="order-review" style={{ textAlign: 'center', margin: '20px 0' }}>
                    <h2 className="margin-bot">How was your experience? <br /> Please rate it from 0-5</h2>
                    <div className="star-rating" style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                        {[...Array(5)].map((star, index) => {
                            const ratingValue = index + 1;

                            return (
                                <label key={index} style={{ position: 'relative', cursor: 'pointer' }}>
                                    <input
                                        type="radio"
                                        name="rating"
                                        value={ratingValue}
                                        onClick={() => setRating(ratingValue)}
                                        style={{ display: 'none' }}
                                    />
                                    <FaStar
                                        className="star"
                                        color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                                        onMouseEnter={() => setHover(ratingValue)}
                                        onMouseLeave={() => setHover(null)}
                                        size={50}
                                    />
                                </label>
                            );
                        })}
                        {hover !== null && (
                            <span className="rating-text" style={{ position: 'absolute', bottom: '60px', width: '100%', textAlign: 'center' }}>
                                {ratingsText[hover - 1]}
                            </span>
                        )}
                    </div>
                    {rating > 0 && (
                        <button
                            className="add-review-button"
                            onClick={() => setShowReviewForm(true)}
                            style={{ backgroundColor: '#007bff', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', marginTop: '10px' }}
                        >
                            <FaPlus /> Add a Review
                        </button>
                    )}
                    {showReviewForm && (
                        <form
                            onSubmit={submitReview}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}
                        >
                            <textarea
                                placeholder="Your Review"
                                value={review}
                                onChange={(e) => setReview(e.target.value)}
                                style={{ width: '300px', height: '100px', padding: '10px', borderRadius: '5px', border: '1px solid #ddd', marginBottom: '10px' }}
                            />
                            <button
                                type="submit"
                                style={{ backgroundColor: '#28a745', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer' }}
                            >
                                Submit Review
                            </button>
                        </form>
                    )}

                </div>

                <Link to="/transactions" style={{ textDecoration: 'none', color: '#007bff', marginTop: '20px' }}>Skip >></Link>
            </div>
        </div>
    );
};

export default CompleteOrderPage;