import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { getDefaultImageUrl } from "../utils/Helper";

const ConversationListItem = ({ conversation, onSelect, userId }) => {
    if (!conversation) return null;

    const userName = `${conversation.firstName} ${conversation.lastName}`;
    const userProfileImageUrl = conversation.imageUrl || getDefaultImageUrl();

    return (
        <ListGroup.Item action onClick={onSelect}>
            <img src={userProfileImageUrl} style={{ width: '20px', height: '20px', marginRight: '12px'}} alt={userName} />
            <strong>{userName}</strong>
        </ListGroup.Item>
    );
};


export default ConversationListItem;