import {Col, Container, Image, Row} from "react-bootstrap";
import teamPhoto from '../assets/fanplay.svg';
import React from "react";
import { Link } from 'react-router-dom';

const Contact = () => {
    return (
        <Container className="about-us">
            <Row className="about-us-body">
                <Col xs={12} md={6}>
                    <Image src={teamPhoto} fluid/>
                </Col>
                <Col xs={12} md={6}>
                    <h2>Support</h2>
                    <p>
                        If you need support please send an email to
                        <b> support@fan-play.com</b> and your support request will be handled quickly,
                    </p>

                    <p>
                        For disputes, please <Link to="/orders/disputes"><b>click here</b></Link>
                    </p>
                    <p>
                        For urgent support, please call <b>020 4586 7195</b>
                    </p>
                    <p>
                        We appreciate your choice to use <b>Fanplay</b>, and consider it our absolute duty to ensure your
                        satisfaction. If you have any questions whatsoever, please email and will quickly get back to you.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Contact;
