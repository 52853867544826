import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GameJobService from "../services/GameJobService";
import Header from '../components/Header';
import UserService from "../services/UserService";
import LPHeader from "../components/LPHeader";
import AuthService from "../services/AuthService";
import UserProfileServiceDetails from "../components/UserProfileServiceDetails";
import MobileUserProfileServiceDetails from "../components/MobileUserProfileServiceDetails";
import StripeService from "../services/StripeService";
import { loadStripe } from "@stripe/stripe-js";
import useWindowSize from '../utils/useWindowSize';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const ServiceDetailPage = () => {
    const { serviceId } = useParams();
    const [service, setService] = useState(null);
    const [user, setUser] = useState(null);
    const [isCurrentUserSeller, setIsCurrentUserSeller] = useState(false);

    const { width } = useWindowSize();
    const breakpoint = 768;

    useEffect(() => {
        const fetchData = async () => {
            const serviceData = await GameJobService.getService(serviceId);
            setService(serviceData);

            const userData = await UserService.getProfileInformation(serviceData.sellerId);
            setUser(userData);

            const userId = localStorage.getItem('userId');
            if (userId === serviceData.sellerId) {
                setIsCurrentUserSeller(true);
            }
        };

        fetchData();
    }, [serviceId]);

    const handlePurchase = async () => {
        const userId = localStorage.getItem('userId');
        if (userId) {
            try {
                // Define the URLs you want to redirect to after payment
                const successUrl = `${window.location.origin}/transactions`;
                const cancelUrl = `${window.location.origin}/payment-cancelled`;

                const sessionId = await StripeService.initiatePayment(userId, service.id, successUrl, cancelUrl);

                const stripe = await stripePromise;
                const { error } = await stripe.redirectToCheckout({
                    sessionId: sessionId.id,
                });

            } catch (error) {
                console.error('Error during service purchase:', error);
            }
        } else {
            console.error('User not logged in');
        }
    };

    const isLoggedIn = AuthService.isLoggedIn();

    if (!service || !user) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            { isLoggedIn ? <Header /> : <LPHeader /> }
            <div className="service-details">
                {width < breakpoint ? (
                    // Render mobile version for small screens
                    <MobileUserProfileServiceDetails
                        user={user}
                        handlePurchase={isCurrentUserSeller ? undefined : handlePurchase}
                        isLoggedIn={isLoggedIn}
                        serviceId={serviceId}
                    />
                ) : (
                    // Render desktop version for larger screens
                    <UserProfileServiceDetails
                        user={user}
                        handlePurchase={isCurrentUserSeller ? undefined : handlePurchase}
                        isLoggedIn={isLoggedIn}
                        serviceId={serviceId}
                    />
                )}
            </div>
        </div>
    );
};

export default ServiceDetailPage;
