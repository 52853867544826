import React, { useState, useEffect } from 'react';
import { Table, Pagination, Card, Col, Row } from 'react-bootstrap';
import AuthService from '../services/AuthService';
import GameJobService from '../services/GameJobService';
import Header from '../components/Header';
import LPHeader from '../components/LPHeader';
import PurchasedServiceCard from '../components/PurchasedServiceCard';
import '../css/TransactionHistory.css'; // Import the CSS file for existing styles
const TransactionHistoryPage = () => {
    const [currentHistoricPage, setCurrentHistoricPage] = useState(1);
    const [transactions, setTransactions] = useState([]);
    const [purchasedServices, setPurchasedServices] = useState([]);
    const [previouslyPurchasedServices, setPreviouslyPurchasedServices] = useState([]);
    const [currentTransactionPage, setCurrentTransactionPage] = useState(1);
    const [currentServicePage, setCurrentServicePage] = useState(1);
    const [itemsPerPage] = useState(4);
    const [transactionsItemsPerPage] = useState(8);
    const [errorMessage, setErrorMessage] = useState("");

    const loggedInUserId = AuthService.getUserId();

    useEffect(() => {
        async function fetchData() {
            try {
                const purchasedServicesData = await GameJobService.getAllInProgressPurchasedGameJobsForBuyer(loggedInUserId);
                setPurchasedServices(purchasedServicesData);

                const previouslyPurchasedServicesData = await GameJobService.getAllCompletedPurchasedGameJobsForBuyer(loggedInUserId);
                setPreviouslyPurchasedServices(previouslyPurchasedServicesData);

                const transactionsData = await GameJobService.getTransactions(loggedInUserId);
                setTransactions(transactionsData);
            } catch (error) {
                console.error(error);
                setErrorMessage(error.message || "An error occurred while fetching data.");
            }
        }

        fetchData();
    }, []);

    const isLoggedIn = AuthService.isLoggedIn();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 427);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 427);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Function to render transactions in a grid layout for mobile
    // Function to render transactions in a grid layout for mobile
    const renderTransactionsGrid = () => (
        <div className="grid-layout">
            {transactions.slice((currentTransactionPage - 1) * itemsPerPage, currentTransactionPage * itemsPerPage).map((transaction) => {
                const transactionDate = new Date(transaction.timestamp);
                const formattedDate = `${transactionDate.getDate()}/${transactionDate.getMonth() + 1}/${transactionDate.getFullYear()}`;
                const hours = transactionDate.getHours();
                const minutes = transactionDate.getMinutes();
                const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
                return (
                    <div key={transaction.id}>
                        <strong>Date & Time:</strong> {formattedDate}&nbsp;&nbsp;&nbsp;&nbsp; {formattedTime}<br/>
                        <strong>Service:</strong> {transaction.name}<br/>
                        <strong>Buyer:</strong> {transaction.buyerFirstName} {transaction.buyerLastName}<br/>
                        <strong>Seller:</strong> {transaction.sellerFirstName} {transaction.sellerLastName}<br/>
                        <strong>Price:</strong> ${transaction.price}
                    </div>
                );
            })}
        </div>
    );

    return (
        <div style={{ marginBottom: '400px' }}>
            {isLoggedIn ? <Header /> : <LPHeader />}
            <div className="container">
                <h2 style={{ marginTop: '10px' }}>Services</h2>
                <div style={{marginLeft: '10px', marginBottom: '4px'}} className="row justify-content-start gap-3">
                    {purchasedServices.slice((currentServicePage - 1) * itemsPerPage, currentServicePage * itemsPerPage).map(service => (
                        <PurchasedServiceCard key={service.id} service={service}/>
                    ))}
                </div>
                {purchasedServices.length > itemsPerPage && (
                    <Pagination>
                        {[...Array(Math.ceil(purchasedServices.length / itemsPerPage)).keys()].map(number => (
                            <Pagination.Item key={number + 1} active={number + 1 === currentServicePage}
                                             onClick={() => setCurrentServicePage(number + 1)}>
                                {number + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                )}
                {purchasedServices.length === 0 && (
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title>No Purchased Services</Card.Title>
                            <Card.Text>You haven't purchased any services</Card.Text>
                        </Card.Body>
                    </Card>
                )}

                <h2 style={{marginTop: '16px'}}>Historic</h2>
                <div style={{ marginTop: '0', marginLeft: '10px', marginBottom: '4px'}} className="row justify-content-start gap-3">
                    {previouslyPurchasedServices.slice((currentHistoricPage - 1) * itemsPerPage, currentHistoricPage * itemsPerPage).map(service => (
                        <PurchasedServiceCard key={service.id} service={service}/>
                    ))}
                </div>
                {previouslyPurchasedServices.length > itemsPerPage && (
                    <Pagination>
                        {[...Array(Math.ceil(previouslyPurchasedServices.length / itemsPerPage)).keys()].map(number => (
                            <Pagination.Item key={number + 1} active={number + 1 === currentHistoricPage}
                                             onClick={() => setCurrentHistoricPage(number + 1)}>
                                {number + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                )}
                {previouslyPurchasedServices.length === 0 && (
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title>No Historical Purchases</Card.Title>
                            <Card.Text>You haven't purchased any services.</Card.Text>
                        </Card.Body>
                    </Card>
                )}

                <h2 style={{marginTop: '16px'}}>Transactions</h2>
                <div style={{marginLeft: '10px', marginBottom: '4px'}} className="row">
                    {transactions.length > 0 ? (
                        isMobile ? renderTransactionsGrid() :
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Date & Time</th>
                                    <th>Service</th>
                                    <th>Buyer</th>
                                    <th>Seller</th>
                                    <th>Price</th>
                                </tr>
                                </thead>
                                <tbody>
                                {transactions.slice((currentTransactionPage - 1) * transactionsItemsPerPage, currentTransactionPage * transactionsItemsPerPage).map((transaction, index) => {
                                    const transactionDate = new Date(transaction.timestamp);
                                    const formattedDate = `${transactionDate.getDate()}/${transactionDate.getMonth() + 1}/${transactionDate.getFullYear()}`;
                                    const hours = transactionDate.getHours();
                                    const minutes = transactionDate.getMinutes();
                                    const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
                                    return (
                                        <tr key={transaction.id}>
                                            <td>{formattedDate}&nbsp;&nbsp;&nbsp;&nbsp; {formattedTime}</td>
                                            <td style={{
                                                maxWidth: '100px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {transaction.name}
                                            </td>
                                            <td>{transaction.buyerFirstName} {transaction.buyerLastName}</td>
                                            <td>{transaction.sellerFirstName} {transaction.sellerLastName}</td>
                                            <td>{parseFloat(transaction.price).toFixed(2)}</td>
                                        </tr>
                                    );
                                })}

                                </tbody>
                            </Table>
                    ) : (
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>No Transaction History</Card.Title>
                                <Card.Text>You have no transaction history.</Card.Text>
                            </Card.Body>
                        </Card>
                    )}
                </div>
                {/* Pagination for Transactions */}
                {transactions.length > transactionsItemsPerPage && (
                    <Pagination className="justify-content-center">
                        {[...Array(Math.ceil(transactions.length / transactionsItemsPerPage)).keys()].map(number => (
                            <Pagination.Item key={number + 1} active={number + 1 === currentTransactionPage}
                                             onClick={() => setCurrentTransactionPage(number + 1)}>
                                {number + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                )}
            </div>
        </div>
    );
};

export default TransactionHistoryPage;
