import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
    return (
        <footer className="footer mt-auto py-3 text-black" style={{backgroundColor: "white" }}>
            <Container>
                <Row>
                    <Col lg={6}>
                        <h5><b>Fanplay</b></h5>
                        <p>Experience gaming in a whole new way, level up your game with coaching, hire game testers, and so much more.</p>
                        <div>
                            <SocialIcon url="https://www.instagram.com/fanplay24" style={{ height: 35, width: 35, marginRight: 15, marginBottom: 15}} target="_blank" fgColor="#fff" />
                            <SocialIcon url="https://www.youtube.com/@Fanplay1" style={{ height: 35, width: 35, marginRight: 15, marginBottom: 15 }} target="_blank" fgColor="#fff" />
                            <SocialIcon url="https://www.facebook.com/fanplay24" style={{ height: 35, width: 35, marginBottom: 15 }} target="_blank" fgColor="#fff" />
                        </div>
                    </Col>
                    <Col lg={3}>
                        <h5><b>Quick Links</b></h5>
                        <ul className="list-unstyled">
                            <li><a href="/become-a-seller">Selling</a></li>
                            <li><a href="/sign-up">Buying</a></li>
                            <li><a href="/play-influencers">Influencer Experiences</a></li>
                            <li><a href="/contact">Contact</a></li>
                            <li><a href="/about">About</a></li>
                            <li><a href="/influencer/apply">Apply for Influencer</a></li>
                            <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                        </ul>
                    </Col>
                    <Col lg={3}>
                        <h5><b>Contact Us</b></h5>
                        <p>Market Way, Coventry, West Midlands</p>
                        <p>Email: contact@fanplay.com</p>
                        <p>Phone: 020 4586 7195</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
