import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import GameJobService from '../services/GameJobService';
import Header from "../components/Header";
import LPHeader from "../components/LPHeader";
import background from "../assets/immg2.png";
import { containsBannedWords } from '../utils/Helper';

const CreateServicePage = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [gameCategory, setGameCategory] = useState('');
    const [game, setGame] = useState('');
    const [price, setPrice] = useState('');
    const [serviceImage, setServiceImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const isLoggedIn = AuthService.isLoggedIn();
    const actualSellerId = isLoggedIn ? localStorage.getItem("userId") : '';

    const gameCategoryMapping = {
        "Digital": "DIGITAL",
        "Card": "CARD",
        "Table Top": "TABLE_TOP",
        "RPG": "RPG"
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const titleCase = (string) => {
        return string.split(' ').map(capitalizeFirstLetter).join(' ');
    }

    const isValidImageSize = async (image) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = URL.createObjectURL(image);
            img.onload = function() {
                resolve(this.width <= 2000 && this.height <= 2000);
            };
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage('');

        // Image size validation
        const validImageSize = await isValidImageSize(serviceImage);
        if (!validImageSize) {
            setErrorMessage("Image dimensions should not exceed 2000x2000 pixels.");
            return;
        }

        setIsSubmitting(true);

        // Validation logic for other fields
        if (!name || !description || !gameCategory || !game || !price || !serviceImage) {
            setErrorMessage("All fields are required.");
            setIsSubmitting(false);
            return;
        }

        // Format the text before sending to the API
        const formattedName = capitalizeFirstLetter(name);
        const formattedDescription = capitalizeFirstLetter(description);
        const formattedGame = titleCase(game);

        // Additional validation logic
        if (!containsBannedWords(formattedName)
            && !containsBannedWords(formattedDescription)
            && !containsBannedWords(gameCategory)
            && !containsBannedWords(formattedGame)
            && price >= 2.5) {

            // Construct service data object
            const serviceData = {
                name: formattedName,
                description: formattedDescription,
                gameCategory: gameCategoryMapping[gameCategory],
                game: formattedGame,
                price: parseFloat(price),
                sellerId: actualSellerId,
                serviceImage
            };

            // Submit service data to the backend
            try {
                await GameJobService.addGameJob(serviceData);
                console.log('Service creation successful!');
                setTimeout(() => {
                    navigate('/selling');
                }, 1000);
            } catch (err) {
                setErrorMessage("Failed to create game job. Please try again.");
                setIsSubmitting(false);
            }
        } else {
            setErrorMessage("Ensure the price is above $2.5 and no fields contain banned words.");
            setIsSubmitting(false);
        }
    };

    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        }}>
            <div className="page-container">
                {isLoggedIn ? <Header /> : <LPHeader />}
                <div className="form-container">
                    <form onSubmit={handleSubmit} className="form-box">
                        <h3 style={{ textAlign: 'center' }}>Create a Service</h3>
                        {/* Form fields */}
                        <div className="service-input">
                            <label>Service Image</label>
                            <input
                                type="file"
                                className="form-control"
                                onChange={(e) => setServiceImage(e.target.files[0])}
                            />
                        </div>

                        <div className="service-input">
                            <label>Service Title</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Service title"
                                maxLength="100"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>

                        <div className="service-input">
                            <label>Service Description</label>
                            <textarea
                                className="form-control"
                                placeholder="Service description"
                                maxLength="4000"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            />
                        </div>

                        <div className="service-input">
                            <label>Game Category</label>
                            <select
                                value={gameCategory}
                                onChange={(e) => setGameCategory(e.target.value)}
                                className="form-control"
                                required
                            >
                                <option value="">Select a category</option>
                                <option value="Digital">Digital</option>
                                <option value="Card">Card</option>
                                <option value="Table Top">Table Top</option>
                                <option value="RPG">RPG</option>
                            </select>
                        </div>

                        <div className="service-input">
                            <label>Game (Input 'New' to indicate a new game will be developed)</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter game name or type 'New' for game development"
                                maxLength="200"
                                value={game}
                                onChange={(e) => setGame(e.target.value)}
                                required
                            />
                        </div>

                        <div className="service-input">
                            <label>Price ($)</label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Service price"
                                value={price}
                                min="2.5"
                                step="0.01"  // Allow decimal values
                                onChange={(e) => setPrice(e.target.value)}
                                required
                            />
                        </div>

                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                        {/* Submit button */}
                        <div className="d-grid">
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                {isSubmitting ? 'Creating...' : 'Create Service'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateServicePage;



