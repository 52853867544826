import React, { useEffect, useState } from 'react';
import UserService from '../services/UserService';
import AuthService from '../services/AuthService';
import Header from '../components/Header';
import { useParams } from 'react-router-dom';
import LPHeader from "../components/LPHeader";
import UserProfileServices from "../components/UserProfileServices";

const UserProfileServicesPage = () => {
    const [user, setUser] = useState(null);
    const { userId } = useParams();

    const fetchUser = async () => {
        try {
            const userData = await UserService.getProfileInformation(userId);
            setUser(userData);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        fetchUser();
    }, [userId]);

    const loggedInUserId = AuthService.getUserId();
    const isLoggedIn = AuthService.isLoggedIn();

    return (
        <div>
            { isLoggedIn ? <Header /> : <LPHeader /> }
            {user && <UserProfileServices user={user} style={{ paddingBottom: "40px", marginBottom: "40px"}}/>}
        </div>
    );
};

export default UserProfileServicesPage;
