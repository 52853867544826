import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import LPHeader from "../components/LPHeader";
import AuthService from "../services/AuthService";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../css/HomePage.css"
import InfluencerForm from "../components/InfluencerForm";

const InfluencerFormPage = () => {
    const isLoggedIn = AuthService.isLoggedIn();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/sign-up');
        }
    }, [isLoggedIn, navigate]);

    return (
        <div id="homeContainer">
            {isLoggedIn ? <Header /> : <LPHeader />}
            {isLoggedIn && <InfluencerForm />}
        </div>
    );
};

export default InfluencerFormPage;