// UserProfileServiceDetails.js
import React, { useState, useEffect } from 'react';
import {Card, Col, Row, Container, Form, Button, Modal } from "react-bootstrap";
import GameJobService from "../services/GameJobService";
import {getDefaultImageUrl, getDefaultServiceImageUrl} from "../utils/Helper";
import { useParams, useNavigate } from 'react-router-dom';
import { LinkContainer } from "react-router-bootstrap";
import {StarFill, Pencil, Trash, Check, Cart} from "react-bootstrap-icons";
import AuthService from "../services/AuthService";
import gameJobService from "../services/GameJobService";
import VisualButton from "./VisualButton";

const UserProfileServiceDetails = ({ user, handlePurchase, isLoggedIn }) => {
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [detailedService, setDetailedService] = useState(null);
    const { serviceId } = useParams();
    const loggedInUserId = AuthService.getUserId();
    const [gameName, setGameName] = useState('');
    const [description, setDescription] = useState('');
    const [gameCategory, setGameCategory] = useState('');
    const [price, setPrice] = useState('0.00');
    const [serviceImage, setServiceImage] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [viewMode, setViewMode] = useState('view');
    // Add a state variable for the service title
    const [serviceName, setServiceName] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editServiceMode, setEditServiceMode] = useState(false);
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');

    const confirmDelete = () => setShowDeleteModal(true);

    const isValidImageSize = async (image) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = URL.createObjectURL(image);
            img.onload = function() {
                resolve(this.width <= 4000 && this.height <= 4000);
            };
        });
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log(file); // Check the file in console
        setServiceImage(file);
    }

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const toggleServiceEditMode = () => {
        setEditServiceMode(!editServiceMode);
    };

    const handleDelete = async () => {
        try {
            await GameJobService.deleteAGameJob(serviceId, loggedInUserId);
            // Handle successful deletion, e.g., redirect or update UI
            setShowDeleteModal(false);

            // Navigate to /selling after a 1-second delay
            setTimeout(() => {
                navigate('/selling');
            }, 1000);
        } catch (error) {
            console.error('Error deleting game job:', error);
            // Handle error
        }
        setShowDeleteModal(false);
    };

    const saveGameJob = async (e) => {
        e.preventDefault();

        const priceNumber = parseFloat(price);
        if (isNaN(priceNumber) || priceNumber < 2.5) {
            setErrorMessage('Price must be greater than or equal to $2.5');
            return;
        }

        const updatedGameJobDTO = {
            name: serviceName,
            description: description,
            gameCategory: gameCategory,
            game: gameName,
            price: priceNumber,
            sellerId: loggedInUserId,
        };

        try {
            await gameJobService.updateGameJob(serviceId, updatedGameJobDTO);

            if (serviceImage) {
                const validImageSize = await isValidImageSize(serviceImage);
                if (!validImageSize) {
                    setErrorMessage("Image dimensions should not exceed 1000x1000 pixels.");
                    return;
                }

                // Separate call for uploading the image
                await gameJobService.updateGameJobImage(serviceId, serviceImage);
            }

            setEditServiceMode(false);
            await fetchServiceDetails();
        } catch (error) {
            console.error('Error saving game job:', error);
            setErrorMessage('Failed to save changes. Please try again.');
        }
    };

    // Mapping from string values to enum values
    const gameCategoryMapping = {
        "Digital": "DIGITAL",
        "Table Top": "TABLE_TOP",
        "RPG": "RPG",
        "Card": "CARD"
    };

    // Define fetchServiceDetails function outside of useEffect
    const fetchServiceDetails = async () => {
        try {
            const data = await GameJobService.getService(serviceId);
            setDetailedService(data);

            // Update form fields with fetched data
            setServiceName(data.name);
            setGameName(data.game);
            setDescription(data.description);
            setGameCategory(data.gameCategory);
            setPrice(data.price.toFixed(2));
            const mappedGameCategory = gameCategoryMapping[data.gameCategory] || data.gameCategory;
            setGameCategory(mappedGameCategory);

        } catch (error) {
            console.error("Error fetching service details: ", error);
        }
    };

    useEffect(() => {
        // Fetch details when the component mounts or serviceId changes
        fetchServiceDetails();
    }, [serviceId]);

    useEffect(() => {
        // Fetch updated details when switching back to view mode
        if (viewMode === 'view') {
            fetchServiceDetails();
        }
    }, [viewMode, serviceId]);

    const handlePurchaseClick = () => {
        if (!isLoggedIn) {
            navigate('/sign-up');
        } else {
            handlePurchase();
        }
    }

    const Skeleton = () => (
        <div id="user-profile-service-details-div">
            <Row>
                <Col xs={12} md={6} className="d-flex flex-column align-items-center">
                    <div className="text-center">
                        <h3 style={{ marginTop: '40px', marginBottom: '4px', width: '200px', height: '24px', background: '#f0f0f0' }}></h3>
                    </div>
                    <div className="d-flex justify-content-center" style={{ width: '100%', height: '200px', background: '#f0f0f0', marginTop: '10px' }}></div>
                    <div style={{ width: '120px', height: '40px', background: '#f0f0f0', marginTop: '20px' }}></div>
                </Col>
                <Col xs={12} md={6} style={{ marginTop: '60px' }}>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={6} md={6}>
                            <h5 style={{ width: '80px', height: '20px', background: '#f0f0f0', marginBottom: '4px' }}></h5>
                            <p style={{ width: '120px', height: '18px', background: '#f0f0f0' }}></p>
                        </Col>
                        <Col xs={6} md={6}>
                            <h5 style={{ width: '80px', height: '20px', background: '#f0f0f0', marginBottom: '4px' }}></h5>
                            <p style={{ width: '120px', height: '18px', background: '#f0f0f0' }}></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={6} style={{ marginTop: '20px' }}>
                            <h5 style={{ width: '80px', height: '20px', background: '#f0f0f0', marginBottom: '4px' }}></h5>
                            <p style={{ width: '80px', height: '18px', background: '#f0f0f0' }}></p>
                        </Col>
                        <Col xs={6} md={6} style={{ marginTop: '20px' }}>
                            <h5 style={{ width: '80px', height: '20px', background: '#f0f0f0', marginBottom: '0' }}></h5>
                            <p style={{ width: '150px', height: '18px', background: '#f0f0f0', marginTop: '4px' }}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="justify-content-end" style={{ marginTop: '20px' }}>
                <Col xs={12} md={7} lg={6} className="text-left">
                    <h5 style={{ width: '120px', height: '20px', background: '#f0f0f0', marginBottom: '10px' }}></h5>
                    <p style={{ width: '100%', height: '80px', background: '#f0f0f0' }}></p>
                </Col>
            </Row>
        </div>
    );

    return (

        <>
            <Container style={{ marginBottom: '60px' }}>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Card className="" style={{ minHeight: '120vh', width: '100%' }}>
                            <Card.Body>
                                <Row style={{marginTop: '32px'}}>
                                    <Col xs={12} md={8}>
                                        {!detailedService ? (
                                            <Skeleton />
                                        ) : editServiceMode ? (

                                            <Form onSubmit={saveGameJob}>
                                                <Form.Group controlId="serviceName">
                                                    <Form.Label style={{ marginTop: '20px', fontWeight: '500' }}>Service Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={serviceName}
                                                        onChange={(e) => setServiceName(e.target.value)}
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="gameName">
                                                    <Form.Label style={{ marginTop: '10px', fontWeight: '500' }}>Game Name</Form.Label>
                                                    <Form.Control type="text" value={gameName} onChange={(e) => setGameName(e.target.value)} />
                                                </Form.Group>

                                                <Form.Group controlId="description">
                                                    <Form.Label style={{ marginTop: '10px', fontWeight: '500' }}>Description</Form.Label>
                                                    <Form.Control type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                                                </Form.Group>

                                                {/* Dropdown for gameCategory */}
                                                <Form.Group controlId="gameCategory">
                                                    <Form.Label style={{ marginTop: '10px', fontWeight: '500' }}>Game Category</Form.Label>
                                                    <Form.Control as="select" value={gameCategory} onChange={(e) => setGameCategory(e.target.value)}>
                                                        <option value="DIGITAL">Digital</option>
                                                        <option value="TABLE_TOP">Table Top</option>
                                                        <option value="RPG">RPG</option>
                                                        <option value="CARD">Card</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group controlId="price">
                                                    <Form.Label style={{ marginTop: '10px', fontWeight: '500' }}>Price (Minimum $2.5)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                        pattern="^\d*(\.\d{0,2})?$"
                                                        title="Price must be a number with up to two decimal places"
                                                    />
                                                </Form.Group>

                                                <Form.Group controlId="serviceImage">
                                                    <Form.Label style={{ marginTop: '10px', fontWeight: '500' }}>Service Image</Form.Label>
                                                    <Form.Control type="file" onChange={handleImageChange} />
                                                </Form.Group>

                                                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                                                <div className="icon-buttons">
                                                    <div className="icon-button-container">
                                                        <VisualButton
                                                            icon={Check}
                                                            text="Submit"
                                                            variant="primary"
                                                            type="submit"
                                                            className="submit-button"
                                                        />
                                                    </div>
                                                </div>
                                            </Form>
                                        ) : (

                                            <div id="user-profile-service-details-div">
                                                <Row>
                                                    {/* Service Image Column */}
                                                    <Col xs={12} md={6} className="d-flex flex-column align-items-center">
                                                        <div className="text-center">
                                                            <h3 style={{ marginTop: '40px', marginBottom: '4px' }}>{detailedService.name}</h3>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <img
                                                                className="user-service-img"
                                                                style={{
                                                                    maxWidth: '100%',
                                                                    marginTop: '0',
                                                                }}
                                                                src={getDefaultServiceImageUrl(detailedService.imageUrl)}
                                                                alt={detailedService.name}
                                                            />
                                                        </div>
                                                        {Number(user.id) !== Number(loggedInUserId) && (
                                                            <div className="icon-buttons">
                                                                <div className="icon-button-container">
                                                                    <VisualButton
                                                                        icon={Cart}
                                                                        text="Purchase"
                                                                        variant="primary"
                                                                        onClick={handlePurchaseClick}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {isLoggedIn && Number(user.id) === Number(loggedInUserId) && (
                                                            <div className="text-center mt-3">
                                                                <VisualButton
                                                                    icon={Pencil}
                                                                    text="Edit"
                                                                    variant="primary"
                                                                    onClick={toggleServiceEditMode}
                                                                    className="button-no-row"
                                                                />
                                                            </div>
                                                        )}
                                                    </Col>

                                                    {/* Service Details Column */}
                                                    <Col xs={12} md={6} style={{ marginTop: '60px' }}>
                                                        <Row style={{ marginTop: '20px' }}>
                                                            {/* Game and Category */}
                                                            <Col xs={6} md={6}>
                                                                <h5 style={{ marginBottom: '4px', fontSize: '18px' }}>Game♟️</h5>
                                                                <p style={{ fontFamily: 'Roboto', fontSize: '18px' }}>{detailedService.game}</p>
                                                            </Col>
                                                            <Col xs={6} md={6}>
                                                                <h5 style={{ marginBottom: '4px', fontSize: '17.5px' }}>Category 🗃️</h5>
                                                                <p style={{ fontFamily: 'Roboto', fontSize: '18px' }}>{detailedService.gameCategory}</p>
                                                            </Col>
                                                        </Row>
                                                        {/* Price and Rating */}
                                                        <Row>
                                                            <Col xs={6} md={6} style={{ marginTop: '20px' }}>
                                                                <h5 style={{ marginBottom: '4px', fontSize: '18px' }}>Price 💰</h5>
                                                                <p className="price" style={{ fontFamily: 'Roboto', fontSize: '18px' }}>${detailedService.price}</p>
                                                            </Col>
                                                            <Col xs={6} md={6} style={{ marginTop: '20px' }}>
                                                                <h5 style={{ marginBottom: '0', fontSize: '18px' }}>Rating ⭐</h5>
                                                                <p style={{ marginTop: '0', fontFamily: 'Roboto', fontSize: '16px' }}>
                                                                    <StarFill style={{ fontSize: '16px' }} className="star-icon" />
                                                                    {detailedService.meanRating} / 5 <span style={{fontSize: '14px'}}>(based on {detailedService.ratingCount} reviews)</span>
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                {/* Description Row */}
                                                <Row className="justify-content-end" style={{ marginTop: '20px' }}>
                                                    <Col xs={12} md={7} lg={6} className="text-left">
                                                        <h5 style={{ fontSize: '18px', marginBottom: '10px' }}>Description ✍️</h5>
                                                        <p style={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: '16px',
                                                            textAlign: 'left',
                                                            wordWrap: 'break-word',
                                                            overflowWrap: 'break-word',
                                                            whiteSpace: 'pre-wrap'
                                                        }}>
                                                            {detailedService.description}
                                                        </p>
                                                    </Col>
                                                </Row>

                                            </div>

                                        )}
                                    </Col>
                                    <Col xs={12} md={4} style={{ marginTop: '60px' }} className="d-flex flex-column align-items-center">
                                        <img
                                            src={getDefaultImageUrl(user.imageUrl)}
                                            alt={`${user.firstName} ${user.lastName}`}
                                            className="service-details-profile-img profile-img-mob-det"
                                        />
                                        {editMode ? (
                                            <>
                                                <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                <Form.Control type="text" value={lastName.put} onChange={(e) => setLastName(e.target.value)} />
                                            </>
                                        ) : (
                                            <LinkContainer to={`/profile/${user.id}`} style={{ marginTop: '10px' }}>
                                                <h3 id="user-prof-serv-det-name">{user.firstName} {user.lastName}</h3>
                                            </LinkContainer>
                                        )}

                                        {isLoggedIn && Number(user.id) === Number(loggedInUserId) && (
                                            <div className="text-center" style={{ marginTop: '0' }}>
                                                <VisualButton
                                                    icon={Trash}
                                                    text="Delete"
                                                    variant="danger"
                                                    onClick={confirmDelete}
                                                    className="button-no-row"
                                                />
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Service</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this service? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UserProfileServiceDetails;