import React, { useState, useEffect } from 'react';
import UserService from "../services/UserService";
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { StarFill, Cart } from 'react-bootstrap-icons';
import {LinkContainer} from "react-router-bootstrap";
import AuthService from "../services/AuthService";
import { getDefaultImageUrl } from "../utils/Helper";
import VisualButton from "./VisualButton";

const ServiceCard = ({ service, onEditClick, editable = false, handlePurchase, onProfile }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const loadUserProfile = async () => {
            const userProfile = await UserService.getProfileInformation(service.sellerId);
            setUser(userProfile);
        };
        loadUserProfile();
    }, [service.id]);

    const handleEditClick = (event) => {
        event.stopPropagation();
        onEditClick && onEditClick(service.id);
    };
    const handleClick = () => {
        navigate(`/services/${service.id}`);
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    const isLoggedIn = AuthService.isLoggedIn();

    return (
        <Card className="mb-4 service-card shadow" onClick={handleClick}>
            <div className="d-flex align-items-center p-1">
                <Card.Img variant="top" className="profile-image" src={user.imageUrl || getDefaultImageUrl()} alt="Profile Image" />
                <LinkContainer to={`/profile/${user.id}`}>
                    <a className="service-details-name-link job-title single-line-text" href={`/profile/${user.id}`}>
                        {user.firstName} {user.lastName}
                    </a>
                </LinkContainer>
            </div>
            <Card.Body>
                <div className="d-flex align-items-center">
                    {!onProfile && (
                        <Card.Img variant="top" src={service.imageUrl} className="service-image" alt="Service Image" />
                    )}
                </div>
                <Card.Text style={{ marginBottom: '10px' }} className="job-title margin-left-elements single-line-text" id="service-card-name">
                    {service.name}
                </Card.Text>
                <Card.Text style={{ marginBottom: '10px', fontFamily: 'Georgia' }} className="margin-left-elements single-line-text">
                    {service.description}
                </Card.Text>
                <Card.Text style={{ marginBottom: '10px' }} className="d-flex align-items-center">
                    <StarFill className="star-icon margin-left-elements" />
                    <span style={{ fontSize: '16px'}}>
                     <b>{service.meanRating} / 5</b> (based on {service.ratingCount} reviews)
                    </span>
                </Card.Text>
                <Card.Text className="price margin-left-elements">
                    Price:  <span style={{ marginLeft: '4px'}}>${service.price}</span>
                </Card.Text>
                {isLoggedIn && (
                    <div className="icon-buttons">
                        <div className="icon-button-container">
                            <VisualButton
                                icon={Cart}
                                text="Purchase"
                                variant="primary"
                                onClick={handlePurchase}
                            />
                        </div>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default ServiceCard;
