import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import GameJobService from '../services/GameJobService';
import Header from '../components/Header';
import LPHeader from "../components/LPHeader";
import AuthService from "../services/AuthService";
import SellerServiceCard from "../components/SellerServiceCard";
import '../css/SellerPage.css';
import {GridContainer} from "../components/GridContainer";
import PurchasedServiceCard from "../components/PurchasedServiceCard";
import {Card, Pagination, Table} from "react-bootstrap";
import StripeService from "../services/StripeService";
import SoldPurchasedServiceCard from "../components/SoldPurchasedServiceCard";

const SellerPage = () => {
    const [services, setServices] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const navigate = useNavigate();
    const [offers, setOffers] = useState([]);
    const [soldAndInProgressServices, setSoldAndInProgressServices] = useState([]);
    const [soldAndCompletedServices, setSoldAndCompletedServices] = useState([]);
    const [itemsPerPage] = useState(10);
    const isLoggedIn = AuthService.isLoggedIn();
    const [show, setShow] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', message: '' });

    // State for service pagination
    const [currentServicePage, setCurrentServicePage] = useState(1);

    // State for offers pagination
    const [currentOfferPage, setCurrentOfferPage] = useState(1);
    const [totalOfferPages, setTotalOfferPages] = useState(0);

    // State for transactions pagination
    const [currentTransactionPage, setCurrentTransactionPage] = useState(1);
    const [totalTransactionPages, setTotalTransactionPages] = useState(0);

    useEffect(() => {
        if (!isLoggedIn) return;

        const userId = localStorage.getItem("userId");

        const loadSoldAndInProgressServices = async () => {
            const soldAndInProgressServicesData = await GameJobService.getInProgressSoldGameJobs(userId);
            setSoldAndInProgressServices(soldAndInProgressServicesData);
        };

        const loadSoldAndCompletedServices = async () => {
            const soldAndCompletedServicesData = await GameJobService.getCompletedSoldGameJobs(userId);
            setSoldAndCompletedServices(soldAndCompletedServicesData);
        };

        const loadServices = async () => {
            const servicesData = await GameJobService.getServicesBySeller(userId);
            setServices(servicesData);
        };

        const loadTransactions = async () => {
            const transactionsData = await GameJobService.getTransactionsBySeller(userId);
            setTransactions(transactionsData);
        };

        const loadOffers = async (includeRejected = false) => {
            const offersData = await GameJobService.getOffersForSeller(userId, includeRejected);

            // Fetch the associated services for each offer and add it to the offer object
            for (let offer of offersData) {
                const serviceData = await GameJobService.getService(offer.gamejobId);
                offer.serviceData = serviceData;
            }

            setOffers(offersData);
        };

        loadSoldAndInProgressServices();
        loadSoldAndCompletedServices();
        loadServices();
        loadTransactions();
        loadOffers(false);
    }, [isLoggedIn]);

    // Pagination logic for services
    const indexOfLastService = currentServicePage * itemsPerPage;
    const indexOfFirstService = indexOfLastService - itemsPerPage;
    const currentServices = services.slice(indexOfFirstService, indexOfLastService);
    const totalServicePages = Math.ceil(services.length / itemsPerPage);

    // Pagination logic for offers
    const indexOfLastOffer = currentOfferPage * itemsPerPage;
    const indexOfFirstOffer = indexOfLastOffer - itemsPerPage;
    const currentOffers = offers.slice(indexOfFirstOffer, indexOfLastOffer);

    useEffect(() => {
        // Update total pages when offers change
        setTotalOfferPages(Math.ceil(offers.length / itemsPerPage));
    }, [offers, itemsPerPage]);

    // Pagination logic for transactions
    const indexOfLastTransaction = currentTransactionPage * itemsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
    const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

    useEffect(() => {
        // Update total pages when transactions change
        setTotalTransactionPages(Math.ceil(transactions.length / itemsPerPage));
    }, [transactions, itemsPerPage]);


    const handleServiceClick = (serviceId) => {
        navigate(`/services/${serviceId}`);
    };

    const handleEditClick = (serviceId) => {
        navigate(`/services/${serviceId}/edit`);
    };

    const handleCreateServiceClick = async () => {
        if (services.length === 0) {
            // Check if user is already onboarded with Stripe
            const onboardingStatus = await StripeService.checkOnboardingStatus(); // This should return an object like {isOnboarded: false}

            if (!onboardingStatus.isOnboarded) {
                try {
                    const response = await StripeService.createConnectAccount();
                    const onboardingLink = response.onboardingLink;

                    showModal("Stripe Onboarding Required", "Please complete the Stripe onboarding process to accept payments for your services.");

                    setTimeout(() => {
                        window.location.href = onboardingLink;
                    }, 3000);

                } catch (error) {
                    showErrorModal("Error", "Unable to initiate Stripe onboarding. Please try again.");
                }
            } else {
                navigate('/create-service');
            }
        } else {
            navigate('/create-service');
        }
    };

    const showModal = (title, message) => {
        setModalContent({ title, message });
        setShow(true);
    };

    const showErrorModal = (title, message) => {
        setModalContent({ title, message });
        setShow(true);
    };


    const renderOffer = (offer) => {
        return (
            <div key={offer.id} className="offer-card-container">
                <h2>{offer.serviceData.name}</h2>
                <p>From {offer.buyerFirstName} {offer.buyerLastName}</p>
                <p>Offer Price: ${offer.offerPrice}</p>
                <p>Status: {offer.status}</p>
            </div>
        );
    };

    // Render function for pagination items
    const renderPaginationItems = (currentPage, setPage, totalPages) => {
        return [...Array(totalPages).keys()].map(number => (
            <Pagination.Item
                key={number + 1}
                active={number + 1 === currentPage}
                onClick={() => setPage(number + 1)}
            >
                {number + 1}
            </Pagination.Item>
        ));
    };

    const renderTransactionsTable = (transactions) => {
        if (!transactions || transactions.length === 0) {
            return <div>No transactions found</div>;
        }

        // Pagination logic
        const indexOfLastTransaction = currentTransactionPage * itemsPerPage;
        const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
        const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

        console.log(transactions);
        return (
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Date & Time</th>
                    <th>Buyer</th>
                    <th>Price</th>
                </tr>
                </thead>
                <tbody>
                {currentTransactions.map((transaction, index) => {
                    const transactionDate = new Date(transaction.timestamp);
                    const formattedDate = `${transactionDate.getDate()}/${transactionDate.getMonth() + 1}/${transactionDate.getFullYear()}`;
                    const hours = transactionDate.getHours();
                    const minutes = transactionDate.getMinutes();
                    const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
                    return (
                        <tr key={transaction.id}>
                            <td>{formattedDate}&nbsp;&nbsp;&nbsp;&nbsp; {formattedTime}</td>
                            <td>{transaction.buyerFirstName} {transaction.buyerLastName}</td>
                            <td>{parseFloat(transaction.price).toFixed(2)}</td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
        );
    };

    const [currentSoldCompletedPage, setCurrentSoldCompletedPage] = useState(1);
    const [totalSoldCompletedPages, setTotalSoldCompletedPages] = useState(0);
    const indexOfLastSoldCompleted = currentSoldCompletedPage * itemsPerPage;
    const indexOfFirstSoldCompleted = indexOfLastSoldCompleted - itemsPerPage;
    const currentSoldCompletedServices = soldAndCompletedServices.slice(indexOfFirstSoldCompleted, indexOfLastSoldCompleted);

    useEffect(() => {
        setTotalSoldCompletedPages(Math.ceil(soldAndCompletedServices.length / itemsPerPage));
    }, [soldAndCompletedServices, itemsPerPage]);


    return (
        <div>
            <div>
            { isLoggedIn ? <Header /> : <LPHeader /> }
                <div className="container" style={{ marginTop: '10px' }}>
                    <h2 style={{ marginTop: '10px' }}>In Progress Services</h2>
                    {soldAndInProgressServices.length > 0 ?
                        <>
                            <GridContainer>
                                {soldAndInProgressServices.map(service => (
                                    <SoldPurchasedServiceCard key={service.id} service={service} />
                                ))}
                            </GridContainer>
                            <Pagination>
                                {[...Array(totalServicePages).keys()].map(number => (
                                    <Pagination.Item key={number + 1} active={number + 1 === currentServicePage} onClick={() => setCurrentServicePage(number + 1)}>
                                        {number + 1}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </>
                        :
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>No In Progress Services</Card.Title>
                                <Card.Text>
                                    You currently have not sold any services, that are still in progress.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    }
                </div>
                <div className="container" style={{ marginTop: '10px' }}>
                    <h2>Delivered Services</h2>
                    {currentSoldCompletedServices.length > 0 ?
                        <>
                            <GridContainer>
                                {currentSoldCompletedServices.map(service => (
                                    <SoldPurchasedServiceCard key={service.id} service={service} />
                                ))}
                            </GridContainer>
                            <Pagination>
                                {renderPaginationItems(currentSoldCompletedPage, setCurrentSoldCompletedPage, totalSoldCompletedPages)}
                            </Pagination>
                        </>
                        :
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>No Completed Services</Card.Title>
                                <Card.Text>
                                    You currently have not delivered any services.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    }
                </div>
                <div className="container" style={{ marginTop: '10px' }}>
                <h2>Your Services</h2>
                <div className="grid-content">
                    {services.map(service => (
                        <div className="grid-item" key={service.id}>
                            <SellerServiceCard
                                service={service}
                                onClick={() => handleServiceClick(service.id)}
                                onEditClick={() => handleEditClick(service.id)}
                                editable
                            />
                        </div>
                    ))}
                </div>
                <Button variant="success" onClick={handleCreateServiceClick} id="add-service-btn"><b>+</b> Add New Service</Button>
            </div>
                <Pagination className="margin-section">
                    {renderPaginationItems(currentServicePage, setCurrentServicePage, totalServicePages)}
                </Pagination>
                {/* Offers section */}
                <div className="container" style={{ marginTop: '10px' }}>
                <h2>My Offers</h2>
                <div className="offers-container">
                    {currentOffers.map(offer => renderOffer(offer))}
                </div>
                <Pagination className="margin-section">
                    {renderPaginationItems(currentOfferPage, setCurrentOfferPage, totalOfferPages)}
                </Pagination>
                </div>

                <div className="container" style={{ marginTop: '10px' }}>
                <h2 id="transactions-heading">My Transactions</h2>
                    {currentTransactions.length > 0 ? (
                        renderTransactionsTable(currentTransactions)
                    ) : (
                        <div>No transactions found</div>
                    )}
                    <Pagination className="margin-section">
                        {renderPaginationItems(currentTransactionPage, setCurrentTransactionPage, totalTransactionPages)}
                    </Pagination>
                </div>
            </div>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalContent.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalContent.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SellerPage;
