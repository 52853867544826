import axios from 'axios';

// Stripe Service
const API_URL = process.env.REACT_APP_API_URL + '/api/v1/stripe';

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

class StripeService {

    async initiatePayment(buyerId, gameJobId) {
        try {
            const payload = {
                buyerId: buyerId,
                gameJobId: gameJobId
            };
            const response = await axiosInstance.post(`/create-checkout-session`, payload);
            return response.data;
        } catch (error) {
            console.error('StripeService error:', error);
            throw error;
        }
    }

    async createConnectAccount() {
        try {
            const response = await axiosInstance.post('/create-connect-account');
            return response.data;
        } catch (error) {
            console.error('StripeService error:', error);
            throw error;
        }
    }

    async checkOnboardingStatus() {
        try {
            const response = await axiosInstance.get('/check-onboarding-status');
            return response.data;
        } catch (error) {
            console.error('StripeService error:', error);
            throw error;
        }
    }
}
export default new StripeService();
