import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import '../App.css';
import {containsBannedWords} from '../utils/Helper';

const SignUp = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate(); // call useNavigate hook
    const [errorMessage, setErrorMessage] = useState("");

    const [termsAndConditions, setTermsAndConditions] = useState("");
    const [privacyPolicy, setPrivacyPolicy] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ type: 'text', content: '' });
    const [hasAgreedToPolicies, setHasAgreedToPolicies] = useState(false);


    useEffect(() => {
        // Fetching the terms and conditions
        fetch('tsandcsupdated.html')
            .then(response => response.text())
            .then(data => {
                setTermsAndConditions(data);
            });

        // Fetching the privacy policy
        fetch('privacy_policy.txt')
            .then(response => response.text())
            .then(data => {
                setPrivacyPolicy(data);
            });
    }, []);


    const handleSignUp = async (event) => {
        event.preventDefault();

        if (!containsBannedWords(firstName) && !containsBannedWords(lastName) && !containsBannedWords(email) && !containsBannedWords(password))
        {
            const registerRequest = {
                firstName,
                lastName,
                email,
                password,
            };
            try {
                const response = await AuthService.registerUser(registerRequest);
                localStorage.setItem('userId', response.userId);
                localStorage.setItem('token', response.access_token);
                localStorage.setItem('role', response.role)

                setTimeout(() => {
                    navigate('/home');
                }, 1000);
            } catch (error) {
                console.error('Error during registration:', error);
                setErrorMessage("Registration failed. Please try again.");
            }
        } else {
            setErrorMessage("Inputs contain banned words");
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSignUp} className="form-box">
                {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                <h3 style={{ textAlign: 'center' }}>Sign Up</h3>
                <div className="mb-3">
                    <label className="label-auth-form">First name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="label-auth-form">Last name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="label-auth-form">Email address</label>
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="label-auth-form">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Enter password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                    />
                </div>

                <div className="mb-3">
                    <input
                        type="checkbox"
                        checked={hasAgreedToPolicies}
                        onChange={() => setHasAgreedToPolicies(!hasAgreedToPolicies)}
                    />
                    <span className="span-sign-up"> I have read and agree to the &nbsp;</span>
                    <span
                        className="span-sign-up clickable-text"
                        onClick={() => {
                            if (termsAndConditions !== "") {
                                setModalContent({ type: 'html', content: termsAndConditions });
                                setShowModal(true);
                            }
                        }}>
        terms & conditions
    </span>
                    <span className="span-sign-up"> and the &nbsp;</span>
                    <span
                        className="span-sign-up clickable-text"
                        onClick={() => {
                            if (privacyPolicy !== "") {
                                setModalContent({ type: 'text', content: privacyPolicy });
                                setShowModal(true);
                            }
                        }}>
        privacy policy
    </span>

                </div>
                <div className="d-grid">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!hasAgreedToPolicies}
                    >
                        Sign Up
                    </button>
                </div>
                <p className="forgot-password text-right" style={{ marginTop: '10px' }}>
                    Already registered <Link to="/login">sign in?</Link>
                </p>
            </form>
            {showModal && (
                <div className="custom-modal">
                    <div className="custom-modal-content">
                        <span className="close-button" onClick={() => setShowModal(false)}>×</span>
                        <div dangerouslySetInnerHTML={{ __html: modalContent.content }} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default SignUp;
