import React from 'react';
import { Button } from 'react-bootstrap';
import '../css/VisualButton.css';

const VisualButton = ({ icon: Icon, text, variant, onClick, type = "button", className = "", style }) => {
    return (
        <div className="icon-button-container">
            <Button variant={variant} onClick={onClick} type={type} className={`${className}`} style={{ ...style }}>
                <Icon className="icon" />
                <span className="icon-text">{text}</span>
            </Button>
        </div>
    );
};

export default VisualButton;
