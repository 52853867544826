import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import UserProfilePage from './pages/UserProfilePage';
import ServicePage from './pages/ServiceDetailPage';
import MessagingPage from './pages/MessagingPage';
import TransactionHistoryPage from './pages/TransactionHistoryPage';
import CreateServicePage from './pages/CreateServicePage';
import ServiceListingPage from './pages/ServiceListingPage';
import SellerPage  from "./pages/SellerPage";
import ServiceDetailPage from "./pages/ServiceDetailPage";
import EditServicePage from "./pages/EditServicePage";
import SignUpPage from "./pages/SignUpPage";
import LoginPage from "./pages/LoginPage";
import AboutUs from "./pages/AboutUsPage";
import BecomeASellerPage from "./pages/BecomeASellerPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import PurchasedServiceDetailPage from "./pages/PurchasedServiceDetailPage";
import DisputesPage from "./pages/DisputesPage";
import CompleteOrderPage from "./pages/CompleteOrderPage";
import PaymentCancelledPage from "./pages/PaymentCancelledPage";
import ContactPage from "./pages/ContactPage";
import PlayInfluencersPage from "./pages/PlayInfluencersPage";
import LPDistributorPage from "./pages/LPDistributorPage";
import UserProfileServicesPage from "./pages/UserProfileServicesPage";
import InfluencerFormPage from "./pages/InfluencerFormPage";
import PasswordResetInfoPage from "./pages/PasswordResetInfoPage";

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LPDistributorPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/service/:id" element={<ServicePage />} />
                <Route path="/profile/:userId" element={<UserProfilePage />} />
                <Route path="/messages/:userId" element={<MessagingPage />} />
                <Route path="/transactions" element={<TransactionHistoryPage />} />
                <Route path="/create-service" element={<CreateServicePage />} />
                <Route path="/service-listing" element={<ServiceListingPage />} />
                <Route path="/selling" element={<SellerPage />} />
                <Route path="/services/:serviceId" element={<ServiceDetailPage />} />
                <Route path="/services/:serviceId/edit" element={<EditServicePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/sign-up" element={<SignUpPage />} />
                <Route path="/become-a-seller" element={<BecomeASellerPage />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/purchased-service/:purchasedServiceId" element={<PurchasedServiceDetailPage />} />
                <Route path="/orders/disputes" element={<DisputesPage />} />
                <Route path="/purchased-service/:purchasedServiceId/complete-order" element={<CompleteOrderPage />} />
                <Route path="/payment-cancelled" element={<PaymentCancelledPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/play-influencers" element={<PlayInfluencersPage />} />
                <Route path="/influencer/apply" element={<InfluencerFormPage />} />
                <Route path="/profile/:userId/services" element={<UserProfileServicesPage />} />
                <Route path="reset-password" element={<PasswordResetInfoPage />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;