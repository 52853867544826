import React from 'react';
import { Button } from 'react-bootstrap';

const PurchaseButton = ({ icon: Icon, text, variant, onClick, type = "button", className = "", style }) => {
    const buttonStyle = {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100px',
        height: '30px',
        padding: '0',
        marginTop: '8px',
        ...style
    };

    const iconStyle = {
        marginRight: '5px',
        marginBottom: '22px'
    };

    const textStyle = {
        display: 'inline',
        verticalAlign: 'middle',
        fontSize: '12px',
        marginRight: '8px'
    };

    return (
        <div className="purchase-button-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '0' }}>
            <Button variant={variant} onClick={onClick} type={type} className={`purchase-button ${className}`} style={buttonStyle}>
                {Icon && <Icon className="icon" style={iconStyle} />}
                <span className="icon-text" style={textStyle}>{text}</span>
            </Button>
        </div>
    );
};

export default PurchaseButton;
