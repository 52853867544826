import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import homeSlideSmall1 from "../assets/slide1homepagesmall.png";
import homeSlideSmall2 from "../assets/slidemobile2.png";
import homeSlideSmall3 from '../assets/homemobileslide2.png';
import homeSlideSmall4 from '../assets/homemobileslide3.png';
import homeSlideSmall5 from '../assets/homemobileslide4.png';
import {useNavigate} from "react-router-dom";
const MobileCarousel = () => {

    const navigate = useNavigate();

    const handleLastSlideClick = () => {
        navigate('/become-a-seller');
    };

    return (
        <Carousel autoPlay infiniteLoop showThumbs={false} className="home-carousel">
            <div className="carousel-div">
                <img className="carousel-image" src={homeSlideSmall2} alt="Slideshow 2" />
            </div>
            <div className="carousel-div">
                <img className="carousel-image" src={homeSlideSmall3} alt="Slideshow 3" />
            </div>
            <div className="carousel-div">
                <img className="carousel-image" src={homeSlideSmall4} alt="Slideshow 4" />
            </div>
            <div className="carousel-div" onClick={handleLastSlideClick}>
                <img className="carousel-image" src={homeSlideSmall5} alt="Slideshow 5" />
            </div>
            <div className="carousel-div">
                <img className="carousel-image" src={homeSlideSmall1} alt="Slideshow 1" />
            </div>
        </Carousel>
    );
};

export default MobileCarousel;
