import React, { useState, useEffect } from 'react';

function TermsAndConditions() {
    const [htmlContent, setHtmlContent] = useState("");

    useEffect(() => {
        fetch(process.env.PUBLIC_URL + '/tsandcsupdated.html')
            .then(response => response.text())
            .then(data => {
                setHtmlContent(data);
            });
    }, []);

    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

export default TermsAndConditions;