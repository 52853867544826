import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import {getDefaultImageUrl, getDefaultServiceImageUrl} from "../utils/Helper";
import GameJobService from "../services/GameJobService";

const PurchasedServiceCard = ({ service }) => {
    const [gameJob, setGameJob] = useState(null);

    useEffect(() => {
        // Make sure service is not null and gameJobId is defined
        if (service && service.gameJobId) {
            GameJobService.getService(service.gameJobId)
                .then(data => setGameJob(data))
                .catch(error => console.error('Error fetching game job details:', error));
        }
    }, [service]); // Depend on the entire service object

    // If service is null, you might want to return null or some placeholder
    if (!service) {
        return null; // Or some placeholder component
    }

    return (
        <LinkContainer to={`/purchased-service/${service.id}`} style={{ maxWidth: '200px', width: '100%', marginTop: '0'}} >
            <Card className="service-card shadow">
                <div className="d-flex align-items-center p-1">
                    <Card.Img variant="top" className="profile-image" src={service.seller.imageUrl || getDefaultImageUrl()} alt="Profile Image" />
                    <a className="service-details-name-link job-title single-line-text" href={`/profile/${service.seller.id}`}>
                        {service.seller.firstName} {service.seller.lastName}
                    </a>
                </div>
                <Card.Body>
                    <div>
                        <Card.Img
                            variant="top"
                            src={getDefaultServiceImageUrl(service.gameJobResponseDTO.imageUrl)}
                            style={{ maxWidth: '200px', width: '100%' }}
                            className="service-image"
                            alt="Service Image" />
                    </div>
                    <Card.Text style={{ marginLeft: '6px' }} className="job-title single-line-text" id="service-card-name">
                        {service.gameJobResponseDTO.name}
                    </Card.Text>
                    <Card.Text style={{ marginLeft: '6px' }} className="single-line-text truncated-description single-line-text">
                        {service.gameJobResponseDTO.description}
                    </Card.Text>
                    <Card.Text style={{ marginLeft: '6px' }} className="price single-line-text">
                        Price: ${service.gameJobResponseDTO.price}
                    </Card.Text>
                </Card.Body>
            </Card>
        </LinkContainer>
    );
};

export default PurchasedServiceCard;
