import React, { useState, useEffect } from 'react';
import UserService from "../services/UserService";
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {Pencil, StarFill} from 'react-bootstrap-icons';
import VisualButton from "./VisualButton";
import {getDefaultServiceImageUrl} from "../utils/Helper";

const ServiceSellerCard = ({ service, onEditClick, editable = false, handlePurchase, onProfile, isLoggedIn }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const loadUserProfile = async () => {
            const userProfile = await UserService.getProfileInformation(service.sellerId);
            setUser(userProfile);
        };
        loadUserProfile();
    }, [service.id]);

    const handleEditClick = (event) => {
        event.stopPropagation();
        onEditClick && onEditClick(service.id);
    };

    const handleClick = () => {
        navigate(`/services/${service.id}`);
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <Card className="mb-4 service-card" onClick={handleClick}>
            <Card.Body>
                <div className="d-flex align-items-center">
                    <Card.Img variant="top" src={getDefaultServiceImageUrl(service.imageUrl)}className="service-image" alt="Service Image" />
                </div>
                <Card.Text className="job-title margin-left-elements single-line-text">
                    {service.name}
                </Card.Text>
                <Card.Text className="margin-left-elements single-line-text">
                    {service.description}
                </Card.Text>
                <Card.Text style={{ marginBottom: '8px', fontSize: '12px' }} className="d-flex align-items-center">
                    <StarFill className="star-icon margin-left-elements" />
                    <span style={{ fontSize: '12px' }}>
                        <b>{service.meanRating} / 5</b> (based on {service.ratingCount} reviews)
                    </span>
                </Card.Text>
                <Card.Text className="price margin-left-elements">
                    Price: ${service.price}
                </Card.Text>
            </Card.Body>
            {editable && (
                <Card.Footer>
                    <div className="icon-buttons" style={{margin: "0"}}>
                        <div className="icon-button-container" style={{margin: "0"}}>
                            <VisualButton
                                icon={Pencil}
                                text={"Edit"}
                                variant="primary"
                                onClick={handleEditClick}
                            />
                        </div>
                    </div>
                </Card.Footer>
            )}
        </Card>
    );
};

export default ServiceSellerCard;
