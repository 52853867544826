import React, { useState, useEffect } from 'react';
import UserService from "../services/UserService";
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getDefaultImageUrl } from "../utils/Helper";
import {StarFill} from "react-bootstrap-icons";
import '../css/UserCard.css';

const UserCard = ({ user }) => {
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState(null);
    const [averageReview, setAverageReview] = useState(null); // State to store average review

    useEffect(() => {
        const loadUserProfileAndReview = async () => {
            if (user && user.id) {
                try {
                    const userProfileData = await UserService.getProfileInformation(user.id);
                    setUserProfile({
                        ...userProfileData,
                        imageUrl: getDefaultImageUrl(userProfileData.imageUrl)
                    });
                } catch (error) {
                    console.error('Error loading user profile:', error);
                }

                try {
                    const averageReviewData = await UserService.getAverageReviewValueForSeller(user.id);
                    setAverageReview(averageReviewData);
                } catch (error) {
                    console.error('Error loading average review value:', error);
                }
            }
        };
        loadUserProfileAndReview();
    }, [user]);

    const handleClick = () => {
        navigate(`/profile/${user.id}/services`);
    };

    if (!userProfile || averageReview === null) {
        return <div>Loading...</div>;
    }

    return (
        <Card className="user-service-card shadow" style={{ marginTop: '6px' }} onClick={handleClick}>
            <Card.Img variant="top" src={userProfile.imageUrl} className="service-image" alt={`${userProfile.firstName} ${userProfile.lastName}`} />
            <Card.Body>
                <Card.Title className="job-title single-line-text" style={{ fontWeight: '500', fontSize: '20px', fontFamily: 'Roboto', marginLeft: '10px', marginTop: '4px'}}>{userProfile.firstName} {userProfile.lastName}</Card.Title>
                <Card.Text className="bio-snapshot" style={{ fontWeight: '500', marginBottom: '20px', marginTop: '4px', fontSize: '14px', fontStyle: 'italic', fontFamily: 'Arial', marginLeft: '10px' }}>{userProfile.bioSnapshot}</Card.Text>
                <Card.Text style={{ marginBottom: '8px', fontSize: '12px' }} className="d-flex align-items-center">
                    <StarFill className="star-icon margin-left-elements" />
                    <span style={{ fontSize: '12px' }}>
                        <b>{averageReview.mean} / 5</b> (based on {averageReview.count} reviews)
                    </span>
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default UserCard;
