import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import GameJobService from '../services/GameJobService';
import Header from '../components/Header';
import LPHeader from '../components/LPHeader';
import background from "../assets/immg2.png";
import { containsBannedWords } from '../utils/Helper';

const EditServicePage = () => {
    const [service, setService] = useState({});
    const [serviceImage, setServiceImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const { serviceId } = useParams();
    const isLoggedIn = AuthService.isLoggedIn();
    const navigate = useNavigate();

    const isValidImageSize = async (image) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = URL.createObjectURL(image);
            img.onload = function () {
                resolve(this.width <= 2000 && this.height <= 2000);
            };
        });
    };

    useEffect(() => {
        const fetchService = async () => {
            const serviceData = await GameJobService.getService(serviceId);
            setService(serviceData);
        };

        fetchService();
    }, [serviceId]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // New image size validation logic
        if (serviceImage) {
            const validImageSize = await isValidImageSize(serviceImage);
            if (!validImageSize) {
                setErrorMessage("Image dimensions should not exceed 2000x2000 pixels.");
                return;
            }
        }

        // Validation logic remains the same
        if (!containsBannedWords(service.name) &&
            !containsBannedWords(service.description) &&
            !containsBannedWords(service.gameCategory) &&
            !containsBannedWords(service.game) &&
            service.price >= 2.5) {

            const updatedServiceData = {
                name: service.name,
                description: service.description,
                gameCategory: service.gameCategory,
                game: service.game,
                price: service.price,
                sellerId: AuthService.getUserId()
            };

            // Update Game Job Details
            try {
                await GameJobService.updateGameJob(serviceId, updatedServiceData);

                // If service image is present, make a separate request to update it
                if (serviceImage) {
                    // Directly pass the file
                    await GameJobService.updateGameJobImage(serviceId, serviceImage);
                }
                navigate('/selling');
            } catch (err) {
                console.error("Failed to update game job: ", err);
                setErrorMessage("Failed to update game job. Please try again.");
            }
        } else {
            setErrorMessage("Ensure the price is above $2.5 and no fields contain banned words.");
        }
    };

    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        }}>
            <div className="page-container">
                {isLoggedIn ? <Header /> : <LPHeader />}
                <div className="form-container">
                    <form onSubmit={handleSubmit} className="form-box">
                        <h3>Edit Service</h3>

                        <div className="mb-3">
                            <label>Service Image</label>
                            <input
                                type="file"
                                className="form-control"
                                onChange={(e) => setServiceImage(e.target.files[0])}
                            />
                        </div>

                        <div className="mb-3">
                            <label>Service Title</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Service title"
                                value={service.name || ''}
                                maxLength="100"
                                onChange={(e) => setService({
                                    ...service,
                                    name: e.target.value
                                })}
                            />
                        </div>

                        <div className="mb-3">
                            <label>Service Description</label>
                            <textarea
                                className="form-control"
                                placeholder="Service description"
                                maxLength="4000"
                                value={service.description || ''}
                                onChange={(e) => setService({
                                    ...service,
                                    description: e.target.value
                                })}
                            />
                        </div>

                        <div className="mb-3">
                            <label>Game Category</label>
                            <select
                                className="form-control"
                                value={service.gameCategory || ''}
                                onChange={(e) => setService({
                                    ...service,
                                    gameCategory: e.target.value
                                })}
                            >
                                <option value="">Select a category</option>
                                <option value="DIGITAL">Digital</option>
                                <option value="RPG">RPG</option>
                                <option value="TABLE_TOP">Table Top</option>
                                <option value="CARD">Card</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label>Game</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Game name"
                                value={service.game || ''}
                                maxLength="200"
                                onChange={(e) => setService({
                                    ...service,
                                    game: e.target.value
                                })}
                            />
                        </div>

                        <div className="mb-3">
                            <label>Price ($)</label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Service price"
                                value={service.price || ''}
                                min="2.5"
                                step="0.01"  // Allow decimal values
                                onChange={(e) => setService({
                                    ...service,
                                    price: e.target.value
                                })}
                            />
                        </div>

                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                        <div className="d-grid">
                            <button type="submit" className="btn btn-primary">
                                Update Service
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditServicePage;
